<template>
    <Loader message="Wait, validating the auth info ..." />
</template>

<script>
    import Loader from '../components/Loader.vue'

    export default {
        components: { Loader },
        data() {
            return {
                loading: true,
            }
        },

        mounted() {
            this.loading = true
            sessionStorage.clear()
            const url = new URL(process.env.VUE_APP_API_ENDPOINT + process.env.VUE_APP_SSO_AUTH_LAMBDA)
            url.searchParams.append('source', location.hostname)
            location.href = url.toString()
        },
    }
</script>
