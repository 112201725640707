<template>
    <div class="container-home">
        <div class="home-dashboard">
            <Sources />
        </div>
    </div>
</template>

<script>
    import Sources from '../components/Sources.vue'

    export default {
        components: {
            Sources,
        },
    }
</script>
