import {
    CREATE_ELEMENT,
    DELETE_ELEMENT,
    FETCH_COLLECTION,
    KEEP_EDITED_ITEM,
    LOGOUT,
    SET_AUTH_TOKEN,
    SET_CAMPAIGN_CODES,
    SET_CAMPAIGN_URLS,
    SET_CONTENT_CODES,
    SET_DOMAINS,
    SET_EDITED_ITEM,
    SET_PUB_CODES,
    SET_PUBLISHER_CAMPAIGNS,
    SET_SOURCES,
    SET_TABLE_PERMISSIONS,
    SET_URL_TEMPLATES,
    SET_URL_VARIABLE_TYPES,
    SET_URL_VARIABLES,
    SET_TUNE_AFFILIATES,
    SET_TUNE_OFFERS,
    SET_TUNE_OFFER_URLS,
    SET_USER,
    SET_USERS,
    UPDATE_EDITED_ITEM,
    UPDATE_ELEMENT,
} from './constants'

export default {
    changeState(state, { type, payload }) {

        switch (type) {
        case CREATE_ELEMENT:
        case DELETE_ELEMENT:
        case FETCH_COLLECTION:
        case UPDATE_ELEMENT:
            state.loading = true
            break
        case SET_AUTH_TOKEN:
            localStorage.setItem('token', payload.token)
            state.token = payload.token
            state.isAuthenticated = true
            state.loading = false
            break
        case LOGOUT:
            localStorage.removeItem('token')
            state.token = null
            state.isAuthenticated = false
            state.loading = false
            break
        case KEEP_EDITED_ITEM:
            state.keepEditedItem = payload
            break
        case SET_CAMPAIGN_CODES:
            state.campaignCodes = payload
            state.loading = false
            break
        case SET_CONTENT_CODES:
            state.contentCodes = payload
            state.loading = false
            break
        case SET_EDITED_ITEM:
            state.editedItem = payload
            state.loading = false
            break
        case SET_DOMAINS:
            state.domains = payload
            break
        case SET_TABLE_PERMISSIONS:
            state.tablePermissions = payload
            break
        case SET_PUB_CODES:
            state.pubCodes = payload
            state.loading = false
            break
        case SET_PUBLISHER_CAMPAIGNS:
            state.publisherCampaigns = payload
            state.loading = false
            break
        case SET_URL_TEMPLATES:
            state.urlTemplates = payload
            state.loading = false
            break
        case SET_URL_VARIABLES:
            state.urlVariables = payload
            state.loading = false
            break
        case SET_URL_VARIABLE_TYPES:
            state.urlVariableTypes = payload
            state.loading = false
            break
        case SET_CAMPAIGN_URLS:
            state.campaignUrls = payload
            state.loading = false
            break
        case SET_USER:
            state.user = payload
            break
        case SET_USERS:
            state.users = payload
            state.loading = false
            break
        case SET_TUNE_AFFILIATES:
            state.affiliates = payload
            state.loading = false
            break
        case SET_TUNE_OFFERS:
            state.tuneOffers = payload
            state.loading = false
            break
        case SET_TUNE_OFFER_URLS:
            state.tuneOfferUrls = payload
            state.loading = false
            break
        case SET_SOURCES:
            state.sources = payload
            break

        case UPDATE_EDITED_ITEM: {
            const { attr, value } = payload
            state.editedItem[attr] = value
            break
        }

        }
    },
}
