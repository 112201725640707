import axios from '../util/axios'

import {
    CREATE_ELEMENT,
    DELETE_ELEMENT,
    CONTENT_CODE_ARRAY,
    CONTENT_CODE_ENDPOINT,
    FETCH_COLLECTION,
    LOGOUT,
    PUB_CODE_ARRAY,
    PUB_CODE_ENDPOINT,
    SET_AUTH_TOKEN,
    SET_CAMPAIGN_CODES,
    SET_CONTENT_CODES,
    SET_DOMAINS,
    SET_EDITED_ITEM,
    SET_PUB_CODES,
    SET_SOURCES,
    SET_TABLE_PERMISSIONS,
    SET_TUNE_AFFILIATES,
    SET_TUNE_OFFERS,
    SET_TUNE_OFFER_URLS,
    SET_URL_TEMPLATES,
    SET_URL_VARIABLES,
    SET_URL_VARIABLE_TYPES,
    SET_USER,
    SET_USERS,
    SET_CAMPAIGN_URLS,
    SOURCES_ARRAY,
    SOURCES_ENDPOINT,
    UPDATE_EDITED_ITEM,
    UPDATE_ELEMENT,
    SET_PUBLISHER_CAMPAIGNS,
    KEEP_EDITED_ITEM,
} from './constants'

export default {
    setAuthToken: ({ commit }, token, expires = 3600000) => {
        commit('chageState', { type: SET_AUTH_TOKEN, payload: { token } })

        setTimeout(() => {
            commit('chageState', { type: LOGOUT })
        }, expires)
    },

    setTablePermissions: ({ commit }, payload) => {
        if (payload === undefined) {
            commit('changeState', { type: SET_TABLE_PERMISSIONS, payload: { add: true, edit: true } })
        } else {
            commit('changeState', { type: SET_TABLE_PERMISSIONS, payload })
        }
    },

    updateEditedItem: ({ commit }, payload) => {
        commit('changeState', { type: UPDATE_EDITED_ITEM, payload } )
    },

    keepEditedItem: ({ commit }, payload) => {
        commit('changeState', { type: KEEP_EDITED_ITEM, payload })
    },

    //Pub Codes
    fetchPubCodes: ({ dispatch }) => {
        dispatch('fetchAll', {
            endpoint: PUB_CODE_ENDPOINT,
            type: SET_PUB_CODES,
        })
    },
    createPubCode: ({ dispatch }) => {
        dispatch('createItem', {
            endpoint: PUB_CODE_ENDPOINT,
            type: SET_PUB_CODES,
            array: PUB_CODE_ARRAY,
        })
    },
    updatePubCode: ({ dispatch }) => {
        dispatch('updateItem', {
            endpoint: PUB_CODE_ENDPOINT,
            type: SET_PUB_CODES,
            array: PUB_CODE_ARRAY,
        })
    },
    deletePubCode: ({ dispatch }) => {
        dispatch('deleteItem', {
            endpoint: PUB_CODE_ENDPOINT,
            type: SET_PUB_CODES,
            array: PUB_CODE_ARRAY,
        })
    },

    //Content Codes
    fetchContentCodes: ({ dispatch }) => {
        dispatch('fetchAll', {
            endpoint: CONTENT_CODE_ENDPOINT,
            type: SET_CONTENT_CODES,
        })
    },
    createContentCode: ({ dispatch }) => {
        dispatch('createItem', {
            endpoint: CONTENT_CODE_ENDPOINT,
            type: SET_CONTENT_CODES,
            array: CONTENT_CODE_ARRAY,
        })
    },
    updateContentCode: ({ dispatch }) => {
        dispatch('updateItem', {
            endpoint: CONTENT_CODE_ENDPOINT,
            type: SET_CONTENT_CODES,
            array: CONTENT_CODE_ARRAY,
        })
    },
    deleteContentCode: ({ dispatch }) => {
        dispatch('deleteItem', {
            endpoint: CONTENT_CODE_ENDPOINT,
            type: SET_CONTENT_CODES,
            array: CONTENT_CODE_ARRAY,
        })
    },


    //Url Variable Types
    fetchUrlVariableTypes: ({ commit }) => {
        axios
            .get('/v1/api/url-variable-type')
            .then(response => {
                commit('changeState', { type: SET_URL_VARIABLE_TYPES, payload: response.data.data })
            })
    },
    createUrlVariableTypes: ({ state, commit, getters }) => {
        commit('changeState', { type: CREATE_ELEMENT })

        axios.post('/v1/api/url-variable-type', state.editedItem)
            .then((res) => {
                const urlVariables = getters.getUrlVariables
                urlVariables.push(res.data.data)
                commit('changeState', { type: SET_URL_VARIABLES, payload: urlVariables })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    updateUrlVariableTypes: ({ state, commit, getters }) => {
        commit('changeState', { type: UPDATE_ELEMENT })

        axios.put(`/v1/api/url-variable-type/${state.editedItem.id}`, state.editedItem)
            .then((res) => {
                const urlVariables = getters.getUrlVariables
                const index = urlVariables.findIndex(uv => uv.id === res.data.data.id)
                urlVariables.splice(index, 1, res.data.data)
                commit('changeState', { type: SET_URL_VARIABLES, payload: urlVariables })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    deleteUrlVariableTypes: ({ state, commit, getters }) => {
        commit('changeState', { type: DELETE_ELEMENT })
        const id = state.editedItem.id

        axios.delete(`/v1/api/url-variable-type/${id}`)
            .then((res) => {
                const urlVariables = getters.getUrlVariables
                const index = urlVariables.findIndex(uv => uv.id === id)
                urlVariables.splice(index, 1)
                commit('changeState', { type: SET_URL_VARIABLES, payload: urlVariables })
            })
            .catch((err) => {
                console.log(err)
            })
    },

    //Url Variables
    fetchUrlVariables: ({ commit }) => {
        axios
            .get('/v1/api/url-variable')
            .then(response => {
                commit('changeState', { type: SET_URL_VARIABLES, payload: response.data.data })
            })
    },
    createUrlVariable: ({ state, commit, getters }) => {
        commit('changeState', { type: CREATE_ELEMENT })

        axios.post('/v1/api/url-variable', state.editedItem)
            .then((res) => {
                console.log(res)
                const urlVariables = getters.getUrlVariables
                urlVariables.push(res.data.data)
                commit('changeState', { type: SET_URL_VARIABLES, payload: urlVariables })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    updateUrlVariable: ({ state, commit, getters }) => {
        commit('changeState', { type: UPDATE_ELEMENT })

        axios.put(`/v1/api/url-variable/${state.editedItem.id}`, state.editedItem)
            .then((res) => {
                const urlVariables = getters.getUrlVariables
                const index = urlVariables.findIndex(uv => uv.id === res.data.data.id)
                urlVariables.splice(index, 1, res.data.data)
                commit('changeState', { type: SET_URL_VARIABLES, payload: urlVariables })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    deleteUrlVariable: ({ state, commit, getters }) => {
        commit('changeState', { type: DELETE_ELEMENT })
        const id = state.editedItem.id

        axios.delete(`/v1/api/url-variable/${id}`)
            .then((res) => {
                const urlVariables = getters.getUrlVariables
                const index = urlVariables.findIndex(uv => uv.id === id)
                urlVariables.splice(index, 1)
                commit('changeState', { type: SET_URL_VARIABLES, payload: urlVariables })
            })
            .catch((err) => {
                console.log(err)
            })
    },

    //Campaign Codes
    createCampaignCode: ({ state, commit, getters }) => {
        commit('changeState', { type: CREATE_ELEMENT })

        axios.post('/v1/api/campaign-url', state.editedItem)
            .then((res) => {
                commit('changeState', { type: SET_EDITED_ITEM, payload: { ...state.editedItem, ...res.data.data } })
            })
            .catch((err) => {
                console.log(err)
            })
    },

    updateCampaignCode: ({ state, commit, getters }) => {
        commit('changeState', { type: UPDATE_ELEMENT })

        return new Promise((resolve, reject) => {
            axios.put(`/v1/api/campaign-url/${state.editedItem.id}`, state.editedItem)
                .then((res) => {
                    const campaignCodes = getters.getCampaignCodes
                    campaignCodes.push(res.data.data)
                    commit('changeState', { type: SET_EDITED_ITEM, payload: { ...state.editedItem, ...res.data.data } })
                    commit('changeState', { type: SET_CAMPAIGN_CODES, payload: campaignCodes })
                    resolve(true)
                })
                .catch((err) => {
                    console.log(err)
                    reject(false)
                })
        })
    },

    // Tune Offers
    fetchDomains: ({ commit }) => {
        commit('changeState', { type: FETCH_COLLECTION })

        axios
            .get('/v1/api/tune-domain')
            .then( (res) => {
                commit('changeState', { type: SET_DOMAINS, payload: res.data.data })
            })
            .catch((err) => {
                console.log(err)
            })
    },

    // Tune Affiliates
    fetchTuneAffiliates: ({ commit }) => {
        commit('changeState', { type: FETCH_COLLECTION })

        axios
            .get('/v1/api/tune-api-affiliate')
            .then( (res) => {
                commit('changeState', { type: SET_TUNE_AFFILIATES, payload: res.data.data })
            })
            .catch((err) => {
                console.log(err)
            })
    },

    // Tune Offers
    fetchTuneOffers: ({ commit }) => {
        commit('changeState', { type: FETCH_COLLECTION })

        axios
            .get('/v1/api/tune-api-offer')
            .then( (res) => {
                commit('changeState', { type: SET_TUNE_OFFERS, payload: res.data.data })
            })
            .catch((err) => {
                console.log(err)
            })
    },

    // Tune Offer URLs
    fetchTuneOfferUrls: ({ commit }) => {
        commit('changeState', { type: FETCH_COLLECTION })

        axios.get('/v1/api/tune-offer-url')
            .then( (res) => {
                commit('changeState', { type: SET_TUNE_OFFER_URLS, payload: res.data.data })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    createTuneOfferUrls: ({ state, commit, getters }) => {
        commit('changeState', { type: CREATE_ELEMENT })

        axios.post('/v1/api/tune-offer-url', state.editedItem)
            .then((res) => {
                console.log(res)
                const tuneOfferUrls = getters.getTuneOfferUrls
                tuneOfferUrls.push(res.data.data)
                commit('changeState', { type: SET_TUNE_OFFER_URLS, payload: tuneOfferUrls })
            })
            .catch((err) => {
                console.log(err)
            })
    },

    updateTuneOfferUrls: ({ state, commit, getters }) => {
        commit('changeState', { type: UPDATE_ELEMENT })

        axios.put(`/v1/api/tune-offer-url/${state.editedItem.id}`, state.editedItem)
            .then((res) => {
                const tuneOfferUrls = getters.getTuneOfferUrls
                const index = tuneOfferUrls.findIndex(tou => tou.id === res.data.data.id)
                tuneOfferUrls.splice(index, 1, res.data.data)
                commit('changeState', { type: SET_TUNE_OFFER_URLS, payload: tuneOfferUrls })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    deleteTuneOfferUrls: ({ state, commit, getters }) => {
        commit('changeState', { type: DELETE_ELEMENT })
        const id = state.editedItem.id

        axios.delete(`/v1/api/tune-offer-url/${id}`)
            .then((res) => {
                const tuneOfferUrls = getters.getTuneOfferUrls
                const index = tuneOfferUrls.findIndex(tou => tou.id === id)
                tuneOfferUrls.splice(index, 1)
                commit('changeState', { type: SET_TUNE_OFFER_URLS, payload: tuneOfferUrls })
            })
            .catch((err) => {
                console.log(err)
            })
    },

    // Campaign URLs
    fetchCampaignUrls: ({ commit }) => {
        commit('changeState', { type: FETCH_COLLECTION })

        axios
            .get('/v1/api/campaign-url')
            .then( (res) => {
                commit('changeState', { type: SET_CAMPAIGN_URLS, payload: res.data.data })
            })
            .catch((err) => {
                console.log(err)
            })
    },

    //URL Templates
    fetchUrlTemplates: ({ commit }) => {
        commit('changeState', { type: FETCH_COLLECTION })

        axios
            .get('/v1/api/url-template')
            .then( (res) => {
                commit('changeState', { type: SET_URL_TEMPLATES, payload: res.data.data })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    createUrlTemplate: ({ state, commit, getters }) => {
        commit('changeState', { type: CREATE_ELEMENT })

        axios.post('/v1/api/url-template', state.editedItem)
            .then((res) => {
                const urlTemplates = getters.getUrlTemplates
                urlTemplates.push(res.data.data)
                commit('changeState', { type: SET_URL_TEMPLATES, payload: urlTemplates })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    updateUrlTemplate: ({ state, commit, getters }) => {
        commit('changeState', { type: UPDATE_ELEMENT })

        axios.put(`/v1/api/url-template/${state.editedItem.id}`, state.editedItem)
            .then((res) => {
                const urlTemplates = getters.getUrlTemplates
                const index = urlTemplates.findIndex(ut => ut.id === res.data.data.id)
                urlTemplates.splice(index, 1, res.data.data)
                commit('changeState', { type: SET_URL_TEMPLATES, payload: urlTemplates })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    deleteUrlTemplate: ({ state, commit, getters }) => {
        commit('changeState', { type: DELETE_ELEMENT })
        const id = state.editedItem.id

        axios.delete(`/v1/api/url-template/${id}`)
            .then((res) => {
                const urlTemplates = getters.getUrlTemplates
                const index = urlTemplates.findIndex(ut => ut.id === id)
                urlTemplates.splice(index, 1)
                commit('changeState', { type: SET_URL_TEMPLATES, payload: urlTemplates })
            })
            .catch((err) => {
                console.log(err)
            })
    },

    //Users
    fetchUsers: ({ commit }) => {
        commit('changeState', { type: FETCH_COLLECTION })

        axios
            .get('/v1/api/user')
            .then( (res) => {
                commit('changeState', { type: SET_USERS, payload: res.data.data })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    fetchUser: ({ commit }, user) => {
        axios
            .get(`/v1/api/user/${user.userId}`)
            .then( (res) => {
                const isAdmin = res.data.data.role === 'admin'
                const userObj = { ...user, ...res.data.data, isAdmin }
                commit('changeState', { type: SET_USER, payload: userObj })
                sessionStorage.setItem('userData', JSON.stringify(userObj))
            })
            .catch((err) => {
                console.log(err)
            })
    },
    createUser: ({ state, commit, getters }) => {
        commit('changeState', { type: CREATE_ELEMENT })

        axios.post('/v1/api/user', state.editedItem)
            .then((res) => {
                const users = getters.getUsers
                users.push(res.data.data)
                commit('changeState', { type: SET_USERS, payload: users })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    updateUser: ({ state, commit, getters }) => {
        commit('changeState', { type: UPDATE_ELEMENT })

        axios.put(`/v1/api/user/${state.editedItem.userId}`, state.editedItem)
            .then((res) => {
                const users = getters.getUsers
                const index = users.findIndex(ut => ut.userId === res.data.data.userId)
                users.splice(index, 1, res.data.data)
                commit('changeState', { type: SET_USERS, payload: users })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    deleteUser: ({ state, commit, getters }) => {
        commit('changeState', { type: DELETE_ELEMENT })
        const id = state.editedItem.userId

        axios.delete(`/v1/api/user/${id}`)
            .then((res) => {
                const users = getters.getUsers
                const index = users.findIndex(ut => ut.userId === id)
                users.splice(index, 1)
                commit('changeState', { type: SET_USERS, payload: users })
            })
            .catch((err) => {
                console.log(err)
            })
    },

    //Publisher Campaigns
    fetchPublisherCampaigns: ({ dispatch }) => {
        dispatch('fetchAll', {
            endpoint: 'funnel-code',
            type: SET_PUBLISHER_CAMPAIGNS,
        })
    },
    createPublisherCampaign: ({ dispatch }) => {
        dispatch('createItem', {
            endpoint: 'funnel-code',
            type: SET_PUBLISHER_CAMPAIGNS,
            array: 'getPublisherCampaigns',
        })
    },
    updatePublisherCampaign: ({ dispatch }) => {
        dispatch('updateItem', {
            endpoint: 'funnel-code',
            type: SET_PUBLISHER_CAMPAIGNS,
            array: 'getPublisherCampaigns',
        })
    },
    deletePublisherCampaign: ({ dispatch }) => {
        dispatch('deleteItem', {
            endpoint: 'funnel-code',
            type: SET_PUBLISHER_CAMPAIGNS,
            array: 'getPublisherCampaigns',
        })
    },

    //Sources
    fetchSources: ({ dispatch }) => {
        dispatch('fetchAll', {
            endpoint: SOURCES_ENDPOINT,
            type: SET_SOURCES,
        })
    },
    createSource: ({ dispatch }) => {
        dispatch('createItem', {
            endpoint: SOURCES_ENDPOINT,
            type: SET_SOURCES,
            array: SOURCES_ARRAY,
        })
    },
    updateSource: ({ dispatch }) => {
        dispatch('updateItem', {
            endpoint: SOURCES_ENDPOINT,
            type: SET_SOURCES,
            array: SOURCES_ARRAY,
        })
    },
    deleteSource: ({ dispatch }) => {
        dispatch('deleteItem', {
            endpoint: SOURCES_ENDPOINT,
            type: SET_SOURCES,
            array: SOURCES_ARRAY,
        })
    },

    //Generic Methods
    fetchAll: ({ commit }, { endpoint, type }) => {
        commit('changeState', { type: FETCH_COLLECTION })

        axios
            .get(`/v1/api/${endpoint}`)
            .then( (res) => {
                commit('changeState', { type, payload: res.data.data })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    createItem: ({ state, commit, getters }, { endpoint, type, array }) => {
        commit('changeState', { type: CREATE_ELEMENT })

        axios.post(`/v1/api/${endpoint}`, state.editedItem)
            .then((res) => {
                const arr = getters[array]
                arr.push(res.data.data)
                commit('changeState', { type, payload: arr })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    updateItem: ({ state, commit, getters }, { endpoint, type, array, id = 'id' }) => {
        commit('changeState', { type: UPDATE_ELEMENT })

        axios.put(`/v1/api/${endpoint}/${state.editedItem[id]}`, state.editedItem)
            .then((res) => {
                const arr = getters[array]
                const index = arr.findIndex(el => el[id] === res.data.data.id)
                arr.splice(index, 1, res.data.data)
                commit('changeState', { type, payload: arr })
            })
            .catch((err) => {
                console.log(err)
            })
    },
    deleteItem: ({ state, commit, getters }, { endpoint, type, array, id = 'id' }) => {
        commit('changeState', { type: DELETE_ELEMENT })

        axios.delete(`/v1/api/${endpoint}/${state.editedItem[id]}`)
            .then((res) => {
                const arr = getters[array]
                const index = arr.findIndex(el => el[id] === id)
                arr.splice(index, 1)
                commit('changeState', { type, payload: arr })
            })
            .catch((err) => {
                console.log(err)
            })
    },
}