<template>
    <crud-table
        :table="tableName"
        :headers="headers"
        :items="items"
        :modal-max-width="'750px'"
        :obj-template="defaultItem"
        item-name="Source"
        @onDelete="deleteSrc"
        @onSave="saveSrc"
    >
        <template #newItemForm>
            <v-col cols="12">
                <v-row align="center" justify="space-between">
                    <v-col cols="6">
                        <ValidationProvider
                            name="Source Name"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <v-text-field
                                :error-messages="errors[0]"
                                label="Source Name"
                                outlined
                                v-model="sourceName"
                            />
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="6">
                        <ValidationProvider
                            name="Short Code"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <v-text-field
                                :error-messages="errors[0]"
                                label="Short Code"
                                outlined
                                v-model="sourceShortCode"
                            />
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <ValidationProvider
                            name="Template URL"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <v-text-field
                                :error-messages="errors[0]"
                                label="Template URL"
                                outlined
                                v-model="tuneTemplateUrl"
                            />
                        </ValidationProvider>
                    </v-col>
                </v-row>

                <div class="aff-separator">
                    <div>Aff Sub Parameters</div>
                    <hr>
                </div>

                <v-row align="center" justify="space-between">
                    <v-col cols="4">
                        <v-text-field
                            label="Aff Sub"
                            outlined
                            v-model="affSub"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Aff Sub2"
                            outlined
                            v-model="affSub2"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Aff Sub3"
                            outlined
                            v-model="affSub3"
                        />
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="4">
                        <v-text-field
                            label="Aff Sub4"
                            outlined
                            v-model="affSub4"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Aff Sub5"
                            outlined
                            v-model="affSub5"
                        />
                    </v-col>
                </v-row>

                <div class="aff-separator">
                    <div>Aff Unique Parameters</div>
                    <hr>
                </div>

                <v-row align="center" justify="space-between">
                    <v-col cols="4">
                        <v-text-field
                            label="Aff Unique1"
                            outlined
                            v-model="affUnique1"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Aff Unique2"
                            outlined
                            v-model="affUnique2"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Aff Unique3"
                            outlined
                            v-model="affUnique3"
                        />
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="4">
                        <v-text-field
                            label="Aff Unique4"
                            outlined
                            v-model="affUnique4"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Aff Unique5"
                            outlined
                            v-model="affUnique5"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </template>
    </crud-table>
</template>

<script>
    import CrudTable from './CrudTable.vue'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        components: { CrudTable },

        data: () => ({
            defaultItem: {
                id: -1,
                sourceName: undefined,
                sourceShortCode: undefined,
                tuneTemplateUrl: undefined,
                affSub: undefined,
                affSub2: undefined,
                affSub3: undefined,
                affSub4: undefined,
                affSub5: undefined,
                affUnique1: undefined,
                affUnique2: undefined,
                affUnique3: undefined,
                affUnique4: undefined,
                affUnique5: undefined,
            },

            headers: [
                { text: 'ID', value: 'id', sortable: true },
                { text: 'Name', value: 'sourceName', sortable: true },
                { text: 'Short Code', value: 'sourceShortCode', sortable: true },
                { text: 'URL', value: 'tuneTemplateUrl', sortable: true, width: '250px' },
                { text: 'Aff Sub', value: 'affSub', sortable: true },
                { text: 'Aff Sub2', value: 'affSub2', sortable: true },
                { text: 'Aff Sub3', value: 'affSub3', sortable: true },
                { text: 'Aff Sub4', value: 'affSub4', sortable: true },
                { text: 'Aff Sub5', value: 'affSub5', sortable: true },
                { text: 'Aff Unique1', value: 'affUnique1', sortable: true },
                { text: 'Aff Unique2', value: 'affUnique2', sortable: true },
                { text: 'Aff Unique3', value: 'affUnique3', sortable: true },
                { text: 'Aff Unique4', value: 'affUnique4', sortable: true },
                { text: 'Aff Unique5', value: 'affUnique5', sortable: true },
            ],

            actions: { text: 'Actions', value: 'actions', sortable: false },

            tableName: 'Sources',
        }),

        computed: {
            ...mapGetters([
                'getSources',
                'getStateAttr',
            ]),

            items() {
                return this.getSources
            },

            editedItem() {
                return this.getStateAttr('editedItem')
            },

            sourceName: {
                get() {
                    return this.editedItem.sourceName
                },

                set(value) {
                    this.updateEditedItem({ attr: 'sourceName', value })
                },
            },

            sourceShortCode: {
                get() {
                    return this.editedItem.sourceShortCode
                },

                set(value) {
                    this.updateEditedItem({ attr: 'sourceShortCode', value })
                },
            },

            tuneTemplateUrl: {
                get() {
                    return this.editedItem.tuneTemplateUrl
                },

                set(value) {
                    this.updateEditedItem({ attr: 'tuneTemplateUrl', value })
                },
            },

            affSub: {
                get() {
                    return this.editedItem.affSub
                },

                set(value) {
                    this.updateEditedItem({ attr: 'affSub', value })
                },
            },

            affSub2: {
                get() {
                    return this.editedItem.affSub2
                },

                set(value) {
                    this.updateEditedItem({ attr: 'affSub2', value })
                },
            },

            affSub3: {
                get() {
                    return this.editedItem.affSub3
                },

                set(value) {
                    this.updateEditedItem({ attr: 'affSub3', value })
                },
            },

            affSub4: {
                get() {
                    return this.editedItem.affSub4
                },

                set(value) {
                    this.updateEditedItem({ attr: 'affSub4', value })
                },
            },

            affSub5: {
                get() {
                    return this.editedItem.affSub5
                },

                set(value) {
                    this.updateEditedItem({ attr: 'affSub5', value })
                },
            },

            affUnique1: {
                get() {
                    return this.editedItem.affUnique1
                },

                set(value) {
                    this.updateEditedItem({ attr: 'affUnique1', value })
                },
            },

            affUnique2: {
                get() {
                    return this.editedItem.affUnique2
                },

                set(value) {
                    this.updateEditedItem({ attr: 'affUnique2', value })
                },
            },

            affUnique3: {
                get() {
                    return this.editedItem.affUnique3
                },

                set(value) {
                    this.updateEditedItem({ attr: 'affUnique3', value })
                },
            },

            affUnique4: {
                get() {
                    return this.editedItem.affUnique4
                },

                set(value) {
                    this.updateEditedItem({ attr: 'affUnique4', value })
                },
            },

            affUnique5: {
                get() {
                    return this.editedItem.affUnique5
                },

                set(value) {
                    this.updateEditedItem({ attr: 'affUnique5', value })
                },
            },

        },

        methods: {
            ...mapActions([
                'fetchSources',
                'createSource',
                'updateSource',
                'deleteSource',
                'updateEditedItem',
                'setTablePermissions',
            ]),

            initialize() {
                this.fetchSources()

                if (this.$isAdmin()) {
                    this.headers.push(this.actions)
                    this.setTablePermissions()
                } else {
                    this.setTablePermissions({ add: true, edit: false })
                }
            },

            saveSrc() {
                const { id } = this.editedItem

                if (id > -1) {
                    this.updateSource()
                } else {
                    this.createSource()
                }
            },

            deleteSrc() {
                this.deleteSource()
            },
        },

        created() {
            this.initialize()
        },
    }
</script>

<style lang="scss">
.aff-separator {
  padding: 25px 0;
  position: relative;
  width: 100%;

  & div {
    background-color: white;
    font-weight: 500;
    margin-top: -11px;
    padding-right: 5px;
    position: absolute;
    width: fit-content;
    z-index: 10;
  }

  & hr {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}
</style>
