<template>
    <div>
        <h1 class="table-name">
            {{ table }}
        </h1>

        <v-data-table
            :headers="headers"
            :items="items"
            :loading="isLoading"
            :search="search"
            sort-by="id"
            class="elevation-1"
            v-if="!isLoading"
        >
            <template #no-data>
                There is no data
            </template>

            <template #loading>
                Loading... Please wait
            </template>

            <template #item.user="{ item }">
                {{ `${item.user.firstName} ${item.user.lastName}` }}
            </template>

            <template #item.generatedUrl="{ item }">
                <clipboard-icon :value="item.generatedUrl" />
            </template>

            <template #item.urlName="{ item }">
                <div style="display: flex; align-items: center">
                    <v-btn
                        color="indigo"
                        dark
                        elevation="1"
                        style="margin-right: 15px"
                        x-small
                    >
                        {{ item.contentCode ? item.contentCode.tuneOfferId : '' }}
                    </v-btn>
                    <p style="margin-bottom: 0 !important">
                        {{ item.urlName }}
                    </p>
                </div>
            </template>

            <template #item.source="{ item }">
                <v-tooltip top>
                    <template #activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >
                            <div class="truncate">
                                {{ item.source.tuneTemplateUrl }}
                            </div>
                        </span>
                    </template>
                    <span>{{ item.source.tuneTemplateUrl }}</span>
                </v-tooltip>
            </template>

            <!-- TuneOfferUrls CRUD Table -->
            <template #item.contentCode="{ item }">
                <v-tooltip top>
                    <template #activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >
                            <div class="truncate">
                                {{ item.contentCode ? item.contentCode.baseUrl : '' }}
                            </div>
                        </span>
                    </template>
                    <span>{{ item.contentCode ? item.contentCode.baseUrl : '' }}</span>
                </v-tooltip>
            </template>

            <!-- Content Codes CRUD Table -->
            <template #item.baseUrl="{ item }">
                <v-tooltip top>
                    <template #activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >
                            <div class="truncate">
                                {{ item.baseUrl }}
                            </div>
                        </span>
                    </template>
                    <span>{{ item.baseUrl }}</span>
                </v-tooltip>
            </template>

            <template #item.tuneTemplateUrl="{ item }">
                <v-tooltip top>
                    <template #activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >
                            <div class="truncate">
                                {{ item.tuneTemplateUrl }}
                            </div>
                        </span>
                    </template>
                    <span>{{ item.tuneTemplateUrl }}</span>
                </v-tooltip>
            </template>

            <template #item.asanaProjectLink="{ item }">
                <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            color="pink"
                            :href="item.asanaProjectLink"
                            icon
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <v-icon>mdi-link</v-icon>
                        </v-btn>
                    </template>
                    <span>Asana Project Link</span>
                </v-tooltip>
            </template>

            <template #item.dateAdded="{ item }">
                {{ item.dateAdded | formatDate }}
            </template>

            <template #item.dateModified="{ item }">
                {{ item.dateModified | formatDate }}
            </template>

            <template #top>
                <v-toolbar flat>
                    <v-text-field
                        v-model="inputSearch"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        class="search-bar"
                    />
                    <v-spacer />
                    <v-dialog
                        v-if="permissions.add || permissions.edit"
                        v-model="dialog"
                        :max-width="modalMaxWidth"
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-if="permissions.add"
                                v-on="on"
                            >
                                New {{ itemName }}
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <ValidationObserver
                                ref="observer"
                                v-slot="{ invalid }"
                            >
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <slot name="newItemForm" />
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        :disabled="invalid"
                                        @click="save"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">
                                Are you sure you want to delete this item?
                            </v-card-title>
                            <v-card-actions>
                                <v-spacer />
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDelete"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirm"
                                >
                                    OK
                                </v-btn>
                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="details" max-width="600px">
                        <v-card>
                            <v-card-title class="text-h5">
                                Details
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <slot name="details" />
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer />
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDetails"
                                >
                                    OK
                                </v-btn>
                                <v-spacer />
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-if="permissions.edit" #item.actions="{ item }">
                <v-icon
                    v-if="viewDetails"
                    small
                    class="mr-2"
                    @click="openDetails(item)"
                >
                    mdi-eye
                </v-icon>

                <v-icon
                    v-if="editAndRedirect"
                    small
                    class="mr-2"
                    @click="editAndRedirectFn(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    v-else-if="!item.isLocked && !item.isLocked === true"
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>

                <v-icon
                    v-if="
                        viewDeleteActn &&
                            !item.isLocked &&
                            !item.isLocked === true
                    "
                    small
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>

        <v-skeleton-loader
            v-bind="attrs"
            v-else
            type="table-heading, table-thead, table-tbody"
        />
    </div>
</template>

<script>
    import ClipboardIcon from './ClipboardIcon'
    import { KEEP_EDITED_ITEM, SET_EDITED_ITEM } from '../store/constants'
    import { mapMutations } from 'vuex'
    import moment from 'moment'

    export default {
        components: { ClipboardIcon },

        filters: {
            formatDate(value) {
                return value ? moment(String(value)).format('DD-MM-YYYY') : ''
            },
        },

        props: {
            editAndRedirect: {
                type: Object,
                default: null,
            },

            itemName: {
                type: String,
                default: 'Item',
            },

            headers: {
                type: Array,
                default: () => [],
            },

            items: {
                type: Array,
                default: () => [],
            },

            objTemplate: {
                type: Object,
                required: true,
            },

            modalMaxWidth: {
                type: String,
                default: '500px',
            },

            table: {
                type: String,
                required: true,
            },

            viewDetails: {
                type: Boolean,
                default: false,
            },

            viewDeleteActn: {
                type: Boolean,
                default: true,
            },
        },

        data: () => ({
            dialog: false,
            dialogDelete: false,
            details: false,
            inputSearch: '',
        }),

        computed: {
            formTitle() {
                return this.editItem.id === -1
                    ? `New ${this.table}`
                    : `Edit ${this.table}`
            },

            title() {
                return this.table + 's Board'
            },

            search() {
                return this.inputSearch
            },

            permissions() {
                return this.$store.getters.getTablePermissions
            },

            isLoading() {
                return this.$store.getters.isLoading && !this.items.length
            },
        },

        methods: {
            ...mapMutations(['changeState']),

            editItem(item) {
                this.changeState({ type: SET_EDITED_ITEM, payload: { ...item } })
                this.dialog = true
                this.$emit('onEditingItem')
            },

            editAndRedirectFn(item) {
                this.changeState({ type: SET_EDITED_ITEM, payload: { ...item } })
                this.changeState({ type: KEEP_EDITED_ITEM, payload: true })
                this.$router.push(this.editAndRedirect)
            },

            deleteItem(item) {
                this.changeState({ type: SET_EDITED_ITEM, payload: { ...item } })
                this.dialogDelete = true
            },

            close() {
                this.$nextTick(() => {
                    this.changeState({
                        type: SET_EDITED_ITEM,
                        payload: { ...this.objTemplate },
                    })
                    this.$refs.observer.reset()
                })
                this.dialog = false
                this.$emit('onModalClose')
            },

            closeDelete() {
                this.$nextTick(() => {
                    this.changeState({
                        type: SET_EDITED_ITEM,
                        payload: { ...this.objTemplate },
                    })
                })
                this.dialogDelete = false
                this.$emit('onModalClose')
            },

            closeDetails() {
                this.$nextTick(() => {
                    this.changeState({
                        type: SET_EDITED_ITEM,
                        payload: { ...this.objTemplate },
                    })
                })
                this.details = false
            },

            deleteItemConfirm() {
                this.closeDelete()
                this.$emit('onDelete')
            },

            openDetails(item) {
                this.changeState({ type: SET_EDITED_ITEM, payload: { ...item } })
                this.details = true
            },

            async save() {
                const isValid = await this.$refs.observer.validate()

                if (isValid) {
                    this.close()
                    this.$emit('onSave')
                }
            },
        },

        watch: {
            dialog(val) {
                val || this.close()
            },

            dialogDelete(val) {
                val || this.closeDelete()
            },

            details(val) {
                val || this.closeDetails()
            },
        },

        created() {
            this.changeState({
                type: SET_EDITED_ITEM,
                payload: { ...this.objTemplate },
            })
        },
    }
</script>

<style>
.search-bar {
    margin-top: 20px;
    max-width: 300px !important;
}
.table-name {
    margin-bottom: 20px;
}
.truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
