<template>
    <div class="container-home">
        <div class="home-dashboard">
            <CampaignUrls />
        </div>
    </div>
</template>

<script>
    import CampaignUrls from '../components/CampaignUrls.vue'

    export default {
        components: {
            CampaignUrls,
        },
    }
</script>
