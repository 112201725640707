<template>
    <v-container>
        <create-campaign-table />
    </v-container>
</template>

<script>
    import CreateCampaignTable from '../components/CreateCampaign.vue'

    export default {
        components: { CreateCampaignTable },
    }
</script>

<style>

</style>