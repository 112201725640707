var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('crud-table',{attrs:{"table":_vm.tableName,"headers":_vm.headers,"items":_vm.items,"obj-template":_vm.defaultItem,"view-details":"","item-name":"Url","view-delete-actn":false},on:{"onDelete":_vm.deleteTOU,"onEditingItem":_vm.setPubCode,"onModalClose":_vm.resetVars,"onSave":_vm.saveTOU},scopedSlots:_vm._u([{key:"newItemForm",fn:function(){return [_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Source","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"items":_vm.sources,"auto-select-first":"","clearable":"","item-value":"id","label":"Source","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.sourceName) + " - " + (item.sourceShortCode)))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.sourceName) + " - " + (item.sourceShortCode)))+" ")]}}],null,true),model:{value:(_vm.sourceId),callback:function ($$v) {_vm.sourceId=$$v},expression:"sourceId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.pubCodes,"auto-select-first":"","clearable":"","item-value":"id","item-text":"pubCode","label":"Pub Code","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.pubCode) + " - " + (item.productSlug)))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.pubCode) + " - " + (item.productSlug)))+" ")]}}]),model:{value:(_vm.pubCodeId),callback:function ($$v) {_vm.pubCodeId=$$v},expression:"pubCodeId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Content Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"items":_vm.contentCodesArr,"auto-select-first":"","clearable":"","item-value":"id","item-text":"contentCode","label":"Content Code","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.contentCode) + " - " + (item.descriptiveName)))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.contentCode) + " - " + (item.descriptiveName)))+" ")]}}],null,true),model:{value:(_vm.contentCodeId),callback:function ($$v) {_vm.contentCodeId=$$v},expression:"contentCodeId"}}),_c('v-alert',{attrs:{"icon":"mdi-link-variant","outlined":"","text":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.ccBaseUrl)+" ")])]}}])})],1)]},proxy:true},{key:"details",fn:function(){return [_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',{attrs:{"id":"detailsBody"}},[_c('tr',[_c('td',[_vm._v("ID")]),_c('td',[_vm._v(_vm._s(_vm.editedItem.id))])]),_c('tr',[_c('td',[_vm._v("User")]),_c('td',[_vm._v(" "+_vm._s(_vm.editedItem.user ? ((_vm.editedItem.user.firstName) + " " + (_vm.editedItem.user.lastName)) : '')+" ")])]),_c('tr',[_c('td',[_vm._v("Offer Template")]),_c('td',[_vm._v(" "+_vm._s(_vm.editedItem.source ? ("" + (_vm.editedItem.source.tuneTemplateUrl)) : '')+" ")])]),_c('tr',[_c('td',[_vm._v("Tune Offer ID")]),_c('td',[_vm._v(" "+_vm._s(_vm.editedItem.contentCode ? ("" + (_vm.editedItem.contentCode.tuneOfferId)) : '')+" ")])]),_c('tr',[_c('td',[_vm._v("Tune Offer URL ID")]),_c('td',[_vm._v(_vm._s(("" + (_vm.editedItem.tuneOfferUrlId))))])]),_c('tr',[_c('td',[_vm._v("Name")]),_c('td',[_vm._v(_vm._s(("" + (_vm.editedItem.urlName))))])]),_c('tr',[_c('td',[_vm._v("Source")]),_c('td',[_vm._v(" "+_vm._s(_vm.editedItem.source ? ((_vm.editedItem.source.sourceShortCode) + " - " + (_vm.editedItem.source.sourceName)) : '')+" ")])]),_c('tr',[_c('td',[_vm._v("Content Code")]),_c('td',[_vm._v(" "+_vm._s(_vm.editedItem.contentCode ? ("" + (_vm.editedItem.contentCode.contentCode)) : 'editedItem.contentCode')+" ")])]),_c('tr',[_c('td',[_vm._v("Generated URL")]),_c('td',[_vm._v(" "+_vm._s(("" + (decodeURIComponent( _vm.editedItem.generatedUrl ))))+" ")])])])]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }