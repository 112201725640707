export const CREATE_ELEMENT = 'CREATE_ELEMENT'

export const DELETE_ELEMENT = 'DELETE_ELEMENT'

export const FETCH_COLLECTION = 'FETCH_COLLECTION'

export const KEEP_EDITED_ITEM = 'KEEP_EDITED_ITEM'

export const UPDATE_ELEMENT = 'UPDATE_ELEMENT'

export const LOGOUT = 'LOGOUT'

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'

export const SET_CAMPAIGN_CODES = 'SET_CAMPAIGN_CODES'

export const SET_CAMPAIGN_URLS = 'SET_CAMPAIGN_URLS'

export const CONTENT_CODE_ARRAY = 'getContentCodes'

export const CONTENT_CODE_ENDPOINT = 'content-code'

export const SET_CONTENT_CODES = 'SET_CONTENT_CODES'

export const SET_DOMAINS = 'SET_DOMAINS'

export const SET_EDITED_INDEX = 'SET_EDITED_INDEX'

export const SET_EDITED_ITEM = 'SET_EDITED_ITEM'

export const SET_TABLE_PERMISSIONS = 'SET_TABLE_PERMISSIONS'

export const PUB_CODE_ARRAY = 'getPubCodes'

export const PUB_CODE_ENDPOINT = 'product-code'

export const SET_PUB_CODES = 'SET_PUB_CODES'

export const SET_PUBLISHER_CAMPAIGNS = 'SET_PUBLISHER_CAMPAIGNS'

export const SET_SOURCES = 'SET_SOURCES'

export const SOURCES_ARRAY = 'getSources'

export const SOURCES_ENDPOINT = 'source'

export const SET_URL_TEMPLATES = 'SET_URL_TEMPLATES'

export const SET_URL_VARIABLE_TYPES = 'SET_URL_VARIABLE_TYPES'

export const SET_URL_VARIABLES = 'SET_URL_VARIABLES'

export const SET_USER = 'SET_USER'

export const SET_USERS = 'SET_USERS'

export const SET_TUNE_AFFILIATES = 'SET_TUNE_AFFILIATES'

export const SET_TUNE_OFFERS = 'SET_TUNE_OFFERS'

export const SET_TUNE_OFFER_URLS = 'SET_TUNE_OFFER_URLS'

export const UPDATE_EDITED_ITEM = 'UPDATE_EDITED_ITEM'