<template>
    <crud-table
        :table="tableName"
        :headers="headers"
        :items="items"
        :obj-template="defaultItem"
        :view-delete-actn="false"
        item-name="User"
        view-details
        @onDelete="deleteUSR"
        @onSave="saveUSR"
    >
        <template #newItemForm>
            <v-col
                cols="12"
                sm="8"
                md="6"
            >
                <v-text-field
                    v-model="lurnUserId"
                    label="User ID"
                    disabled
                    outlined
                />
                <v-text-field
                    v-model="fullName"
                    label="Full Name"
                    disabled
                    outlined
                />

                <ValidationProvider
                    name="Affiliate ID"
                    rules="required|alpha_num"
                    v-slot="{ errors }"
                >
                    <v-text-field
                        v-model="affiliateId"
                        label="Affiliate ID"
                        outlined
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>

                <ValidationProvider
                    name="Role"
                    rules="required"
                    v-slot="{ errors }"
                >
                    <v-autocomplete
                        :error-messages="errors[0]"
                        :items="['admin', 'user']"
                        auto-select-first
                        clearable
                        label="Role"
                        outlined
                        v-model="role"
                    />
                </ValidationProvider>
            </v-col>
        </template>

        <template #details>
            <v-simple-table>
                <template #default>
                    <tbody id="detailsBody">
                        <tr>
                            <td>User ID</td>
                            <td>{{ editedItem.userId }}</td>
                        </tr>
                        <tr>
                            <td>Lurn User ID</td>
                            <td>{{ editedItem.lurnUserId ? `${editedItem.lurnUserId}` : '' }}</td>
                        </tr>
                        <tr>
                            <td>Affiliate ID</td>
                            <td>{{ editedItem.affiliateId ? `${editedItem.affiliateId}` : '' }}</td>
                        </tr>
                        <tr>
                            <td>Role</td>
                            <td>{{ editedItem.role ? `${editedItem.role}` : '' }}</td>
                        </tr>
                        <tr>
                            <td>User</td>
                            <td>{{ fullName }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
    </crud-table>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import CrudTable from './CrudTable.vue'

    export default {
        components: { CrudTable },

        data: () => ({

            defaultItem: {
                userId: -1,
                lurnUserId: undefined,
                affiliateId: undefined,
                role: undefined,
                firstName: undefined,
                lastName: undefined,
                dateAdded: undefined,
                dateModified: undefined,
            },

            headers: [
                { text: 'ID', value: 'userId', align: 'start', sortable: false },
                { text: 'Lurn User ID', value: 'lurnUserId', sortable: true },
                { text: 'Affiliate ID', value: 'affiliateId', sortable: true },
                { text: 'Role', value: 'role', sortable: true },
                { text: 'Name', value: 'firstName', sortable: true },
                { text: 'Last name', value: 'lastName', sortable: true },
                // { text: 'Date Added', value: 'dateAdded', sortable: true, width: '150px' },
                // { text: 'Date Modified', value: 'dateModified', sortable: true, width: '150px' },
            ],

            actions: { text: 'Actions', value: 'actions', sortable: false, align: 'center' },

            tableName: 'Users',
        }),

        computed: {
            ...mapGetters( ['getUsers', 'getStateAttr'] ),

            items () {
                return this.getUsers
            },

            editedItem () {
                return this.getStateAttr('editedItem')
            },

            affiliateId: {
                get() {
                    return this.editedItem.affiliateId
                },

                set(value) {
                    this.editedItem.affiliateId = value
                },
            },

            role: {
                get() {
                    return this.editedItem.role
                },

                set(value) {
                    this.editedItem.role = value
                },
            },

            lurnUserId () {
                return this.editedItem.lurnUserId
            },

            fullName () {
                return `${this.editedItem.firstName} ${this.editedItem.lastName}`
            },
        },

        methods: {
            ...mapActions([
                'fetchUsers', 'createUser', 'updateUser',
                'deleteUser', 'updateEditedItem', 'setTablePermissions',
            ]),

            initialize () {
                this.fetchUsers()

                if(this.$isAdmin()) {
                    this.headers.push(this.actions)
                    this.setTablePermissions({ add: false, edit: true })
                } else {
                    this.setTablePermissions({ add: false, edit: false })
                }
            },

            saveUSR () {

                const { userId } = this.editedItem

                if ( userId > -1 ) {
                    this.updateUser()
                } else {
                    this.createUser()
                }
            },

            deleteUSR () {
                this.deleteUser()
            },
        },

        created () {
            this.initialize()
        },
    }
</script>
