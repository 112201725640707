var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('crud-table',{attrs:{"table":_vm.tableName,"headers":_vm.headers,"items":_vm.items,"obj-template":_vm.defaultItem,"item-name":"Content Code"},on:{"onDelete":_vm.deleteCC,"onSave":_vm.saveCC},scopedSlots:_vm._u([{key:"newItemForm",fn:function(){return [_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"name":"Content Code","rules":"required|alpha_dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Content Code","outlined":""},model:{value:(_vm.contentCode),callback:function ($$v) {_vm.contentCode=$$v},expression:"contentCode"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Offer ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"filter":_vm.tuneOfferIdFilter,"items":_vm.tuneOffersArr,"auto-select-first":"","clearable":"","item-value":"id","item-text":"name","label":"Tune Offer ID","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.id) + " - " + (item.name)))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.id) + " - " + (item.name)))+" ")]}}],null,true),model:{value:(_vm.tuneOfferId),callback:function ($$v) {_vm.tuneOfferId=$$v},expression:"tuneOfferId"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Pub Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"filter":_vm.pubCodesFilter,"items":_vm.pubCodesArr,"auto-select-first":"","clearable":"","item-value":"id","item-text":"pubCode","label":"Pub Code","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.pubCode) + " - " + (item.productSlug)))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.pubCode) + " - " + (item.productSlug)))+" ")]}}],null,true),model:{value:(_vm.pubCodeId),callback:function ($$v) {_vm.pubCodeId=$$v},expression:"pubCodeId"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Descriptive Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Descriptive Name","outlined":""},model:{value:(_vm.descriptiveName),callback:function ($$v) {_vm.descriptiveName=$$v},expression:"descriptiveName"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Base URL","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Base URL","outlined":""},model:{value:(_vm.baseUrl),callback:function ($$v) {_vm.baseUrl=$$v},expression:"baseUrl"}})]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }