import axios from 'axios'

const defaultOptions = {
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    responseType: 'json',
    responseEncoding: 'utf8',
    headers: {
        'Content-Type': 'application/json',
    },
}

const instance = axios.create( defaultOptions )

instance.interceptors.request.use(function (config) {
    const method = config.method.toUpperCase()
    const token = localStorage.getItem('token')

    if (token && method !== 'OPTIONS') {
        config.headers = {
            ...config.headers,
            'Authorization': `Bearer ${token}`,
        }
    }

    return config
})

export default instance