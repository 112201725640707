<template>
    <url-templates />
</template>

<script>
    import UrlTemplates from '../components/UrlTemplates.vue'

    export default {
        components: { UrlTemplates },
    }
</script>

<style>

</style>