<template>
    <crud-table
        :table="tableName"
        :headers="headers"
        :items="items"
        :obj-template="defaultItem"
        item-name="Publisher Campaign"
        @onDelete="deletePC"
        @onSave="savePC"
    >
        <template #newItemForm>
            <v-col cols="12">
                <ValidationProvider
                    name="Campaign Code"
                    rules="required|max:3|alpha_dash"
                    v-slot="{ errors }"
                >
                    <v-text-field
                        v-model="pubCampaignCode"
                        label="Campaign Code"
                        outlined
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>

                <ValidationProvider
                    name="Product Slug"
                    rules="required|alpha_dash"
                    v-slot="{ errors }"
                >
                    <v-text-field
                        v-model="productSlug"
                        label="Product Slug"
                        outlined
                        required
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>

                <ValidationProvider
                    name="Asana Project Link"
                    rules="url"
                    v-slot="{ errors }"
                >
                    <v-text-field
                        v-model="asanaProjectLink"
                        label="Asana Project Link"
                        outlined
                        required
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>

                <ValidationProvider
                    name="Description"
                    rules="required"
                    v-slot="{ errors }"
                >
                    <v-textarea
                        v-model="description"
                        label="Description"
                        outlined
                        required
                        rows="5"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
            </v-col>
        </template>
    </crud-table>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import CrudTable from './CrudTable.vue'

    export default {
        components: { CrudTable },

        data: () => ({
            defaultItem: {
                id: -1,
                pubCampaignCode: undefined,
                description: undefined,
                productSlug: undefined,
                asanaProjectLink: undefined,
            },

            headers: [
                { text: 'ID', align: 'start', sortable: false, value: 'id' },
                {
                    text: 'Campaign Code',
                    value: 'pubCampaignCode',
                    align: 'center',
                    sortable: true,
                },
                {
                    text: 'Product Slug',
                    value: 'productSlug',
                    align: 'center',
                    sortable: true,
                },
                {
                    text: 'Asana Project Link',
                    value: 'asanaProjectLink',
                    align: 'center',
                    sortable: true,
                },
            ],

            actions: { text: 'Actions', value: 'actions', sortable: false },

            tableName: 'Publisher Campaign',
        }),

        computed: {
            ...mapGetters(['getPublisherCampaigns', 'getStateAttr']),

            items() {
                return this.getPublisherCampaigns
            },

            editedItem() {
                return this.getStateAttr('editedItem')
            },

            pubCampaignCode: {
                get() {
                    return this.editedItem.pubCampaignCode
                },

                set(value) {
                    this.updateEditedItem({ attr: 'pubCampaignCode', value })
                },
            },

            productSlug: {
                get() {
                    return this.editedItem.productSlug
                },

                set(value) {
                    this.updateEditedItem({ attr: 'productSlug', value })
                },
            },

            asanaProjectLink: {
                get() {
                    return this.editedItem.asanaProjectLink
                },

                set(value) {
                    this.updateEditedItem({ attr: 'asanaProjectLink', value })
                },
            },

            description: {
                get() {
                    return this.editedItem.description
                },

                set(value) {
                    this.updateEditedItem({ attr: 'description', value })
                },
            },
        },

        methods: {
            ...mapActions([
                'fetchPublisherCampaigns',
                'createPublisherCampaign',
                'updatePublisherCampaign',
                'deletePublisherCampaign',
                'updateEditedItem',
                'setTablePermissions',
            ]),

            initialize() {
                this.fetchPublisherCampaigns()

                if (this.$isAdmin()) {
                    this.headers.push(this.actions)
                    this.setTablePermissions()
                } else {
                    this.setTablePermissions({ add: true, edit: false })
                }
            },

            savePC() {
                const { id } = this.editedItem

                if (id > -1) {
                    this.updatePublisherCampaign()
                } else {
                    this.createPublisherCampaign()
                }
            },

            deletePC() {
                this.deletePublisherCampaign()
            },
        },

        created() {
            this.initialize()
        },
    }
</script>
