<template>
    <div class="container-home">
        <div class="home-dashboard">
            <UserMgmt />
        </div>
    </div>
</template>

<script>
    import UserMgmt from '../components/UserMgmt.vue'

    export default {
        components: {
            UserMgmt,
        },
    }
</script>