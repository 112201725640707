<template>
    <crud-table
        :table="tableName"
        :headers="headers"
        :items="items"
        item-name="Template"
        :obj-template="defaultItem"
        @onDelete="deleteUT"
        @onSave="saveUT"
    >
        <template #newItemForm>
            <v-col
                cols="12"
                sm="8"
                md="6"
            >
                <v-autocomplete
                    :items="urlTemplateLabels"
                    v-model="urlSource"
                    item-value="id"
                    item-text="variableValue"
                    label="URL Template Label"
                    outlined
                    clearable
                />


                <v-text-field
                    v-model="urlTemplate"
                    label="URL Template"
                    prepend-inner-icon=""
                    outlined
                />
            </v-col>
        </template>
    </crud-table>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import CrudTable from './CrudTable.vue'

    export default {
        components: { CrudTable },
        data: () => ({
            defaultItem: {
                id: -1,
                userId: -1,
                urlSourceId: -1,
                urlTemplate: '',
            },

            headers: [
                { text: 'ID', align: 'start', sortable: false, value: 'id' },
                { text: 'Label', align: 'start', value: 'urlSource.variableValue', sortable: true },
                { text: 'Short Code', align: 'start', value: 'urlSource.shortValue', sortable: true },
                { text: 'URL Template', align: 'start', value: 'urlTemplate', sortable: true },
            ],

            actions: { text: 'Actions', value: 'actions', sortable: false },

            tableName: 'URL Templates',

            urlVT: undefined,
        }),

        computed: {
            ...mapGetters( ['getStateAttr', 'getUrlVariablesByTypeId', 'getUrlVariablesOfTypeUtmSource'] ),

            items() {
                return this.getStateAttr('urlTemplates')
            },

            editedItem () {
                return this.getStateAttr('editedItem')
            },

            urlVariableTypes() {
                return this.getStateAttr('urlVariableTypes')
            },

            urlVariables () {
                return this.getUrlVariablesByTypeId(this.urlVT)
            },

            urlTemplateLabels () {
                return this.getUrlVariablesOfTypeUtmSource
            },

            urlSource: {
                get() {
                    return this.editedItem.urlSourceId ? this.editedItem.urlSourceId : this.editedItem.urlSource.id
                },

                set(value) {
                    this.updateEditedItem({ attr: 'urlSourceId', value })
                },
            },

            urlTemplate: {
                get() {
                    return this.editedItem.urlTemplate
                },

                set(value) {
                    this.updateEditedItem({ attr: 'urlTemplate', value })
                },
            },
        },

        methods: {
            ...mapActions([
                'fetchUrlTemplates', 'createUrlTemplate', 'updateUrlTemplate',
                'deleteUrlTemplate', 'updateEditedItem', 'fetchUrlVariableTypes',
                'fetchUrlVariables', 'setTablePermissions',
            ]),

            initialize() {
                this.fetchUrlTemplates()
                this.fetchUrlVariableTypes()
                this.fetchUrlVariables()

                if(this.$isAdmin()) {
                    this.headers.push(this.actions)
                    this.setTablePermissions()
                } else {
                    this.setTablePermissions({ add: true, edit: false })
                }
            },

            saveUT () {
                this.updateEditedItem({ attr: 'userId', value: 1 })

                const { id } = this.editedItem

                if ( id > -1) {
                    delete this.editedItem.urlSource

                    this.updateUrlTemplate()
                } else {
                    this.createUrlTemplate()
                }
            },

            deleteUT() {
                this.deleteUrlTemplate()
            },
        },

        created() {
            this.initialize()
        },
    }
</script>
