<template>
    <v-app>
        <lateral-menu />

        <v-main>
            <v-container fluid>
                <router-view />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import Header from './components/Header'
    import LateralMenu from './components/LateralMenu'
    import { mapActions } from 'vuex'

    export default {
        name: 'App',

        components: {
            Header,
            LateralMenu,
        },

        data: () => ({
            drawer: true,
        }),

        methods: {
            ...mapActions(['fetchUrlVariables', 'fetchPubCodes', 'fetchContentCodes']),
        },

        created () {
            this.fetchUrlVariables()
            this.fetchPubCodes()
            this.fetchContentCodes()
        },
    }
</script>

<style lang="scss">
.container-home {
    width: calc(100% - 2em);;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1em;
    padding-right: 1em;
}

.home-dashboard {
    width: calc(100% -2em);
    display: flex;
    margin-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 5px;
    justify-content: center;
    flex-direction: column;

    h1 {
        text-align: center;
    }
}
</style>