import Vue from 'vue'
import Router from 'vue-router'

import CampaignUrls from './views/CampaignUrls.vue'
import ContentCodesView from './views/ContentCodesView.vue'
import CreateCampaign from './views/CreateCampaign.vue'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import NotFound from './views/NotFound.vue'
import PubCodes from './views/PubCodes.vue'
import PublisherCampaignView from './views/PublisherCampaignView.vue'
import SSOLogin from './views/SSOLogin.vue'
import TuneOfferUrls from './views/TuneOfferUrls.vue'
import UrlTemplatesView from './views/UrlTemplatesView.vue'
import UrlVariables from './views/UrlVariables.vue'
import UrlVariableTypes from './views/UrlVariableTypes.vue'
import UserMgmtView from './views/UserMgmtView.vue'
import SourcesView from './views/SourcesView.vue'

Vue.use(Router)
Vue.router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '*',
            name: 'Not Found',
            component: NotFound,
        },
        {
            path: '/',
            name: 'home',
            redirect: { name: 'campaign-urls' },
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/lurn/login',
            name: 'sso_login',
            component: SSOLogin,
        },
        {
            path: '/url-variable-types',
            name: 'url_variable_types',
            component: UrlVariableTypes,
            meta: {
                auth: true,
            },
        },
        {
            path: '/url-variables',
            name: 'url_variables',
            component: UrlVariables,
            meta: {
                auth: true,
            },
        },
        {
            path: '/pub-codes',
            name: 'pub-codes',
            component: PubCodes,
            meta: {
                auth: true,
            },
        },
        {
            path: '/publisher-campaigns',
            name: 'publisher-campaigns',
            component: PublisherCampaignView,
            meta: {
                auth: true,
            },
        },
        {
            path: '/content-codes',
            name: 'content-codes',
            component: ContentCodesView,
            meta: {
                auth: true,
            },
        },
        {
            path: '/create-campaign',
            name: 'create-campaign',
            component: CreateCampaign,
            meta: {
                auth: true,
            },
        },
        {
            path: '/tune-offer-url',
            name: 'tune-offer-url',
            component: TuneOfferUrls,
            meta: {
                auth: true,
            },
        },
        {
            path: '/campaign-urls',
            name: 'campaign-urls',
            component: CampaignUrls,
            meta: {
                auth: true,
            },
        },
        {
            path: '/url-templates',
            name: 'url-templates',
            component: UrlTemplatesView,
            meta: {
                auth: true,
            },
        },
        {
            path: '/users',
            name: 'users',
            component: UserMgmtView,
            meta: {
                auth: true,
            },
        },
        {
            path: '/sources',
            name: 'sources',
            component: SourcesView,
            meta: {
                auth: true,
            },
        },
    ],
})

export default Vue.router