<template>
    <v-col
        cols="12"
        style="display: flex; flex-direction: column; align-items: center;"
    >
        <h1 class="table-name">
            <span v-if="!editedItem.id">Create New </span>
            <span v-else>Edit</span>
            Campaign
        </h1>

        <v-stepper v-model="e1" style="width: 100%;">
            <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">
                    Step 1
                </v-stepper-step>

                <v-divider />

                <v-stepper-step :complete="e1 > 2" step="2">
                    Step 2
                </v-stepper-step>

                <v-divider />

                <v-stepper-step :complete="e1 > 3" step="3">
                    Step 3
                </v-stepper-step>

                <v-divider />

                <v-stepper-step step="4">
                    Done
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <ValidationObserver ref="sOneObserver" v-slot="{ invalid }">
                        <v-card class="mb-12">
                            <v-row style="margin-top: 10px;">
                                <v-col cols="12">
                                    <ValidationProvider
                                        name="Source"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <v-autocomplete
                                            :error-messages="errors[0]"
                                            :items="allSources"
                                            clearable
                                            item-value="id"
                                            label="Source"
                                            outlined
                                            v-model="sourceId"
                                        >
                                            <template #item="{ item }">
                                                {{
                                                    `${item.sourceName} - ${item.sourceShortCode}`
                                                }}
                                            </template>
                                            <template #selection="{ item }">
                                                {{
                                                    `${item.sourceName} - ${item.sourceShortCode}`
                                                }}
                                            </template>
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <ValidationProvider
                                        name="Channel"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <v-autocomplete
                                            :error-messages="errors[0]"
                                            :items="
                                                getVariablesArr('utm_channel')
                                            "
                                            clearable
                                            item-value="id"
                                            item-text="variableValue"
                                            label="Channel"
                                            outlined
                                            v-model="channelId"
                                        >
                                            <template #item="{ item }">
                                                {{
                                                    `${item.variableValue} - ${item.shortValue}`
                                                }}
                                            </template>
                                            <template #selection="{ item }">
                                                {{
                                                    `${item.variableValue} - ${item.shortValue}`
                                                }}
                                            </template>
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider
                                        name="Pub Code"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <v-autocomplete
                                            :error-messages="errors[0]"
                                            :items="allPubCodes"
                                            clearable
                                            item-text="pubCode"
                                            item-value="id"
                                            label="Pub Codes"
                                            outlined
                                            v-model="pubCodeId"
                                        >
                                            <template #item="{ item }">
                                                {{
                                                    `${item.pubCode} - ${item.productSlug}`
                                                }}
                                            </template>
                                            <template #selection="{ item }">
                                                {{
                                                    `${item.pubCode} - ${item.productSlug}`
                                                }}
                                            </template>
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider
                                        name="Publisher Campaign"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <v-autocomplete
                                            :error-messages="errors[0]"
                                            :items="allPubCampaigns"
                                            clearable
                                            item-text="pubCampaignCode"
                                            item-value="id"
                                            label="Publisher Campaign"
                                            outlined
                                            v-model="pubCampaignId"
                                        >
                                            <template #item="{ item }">
                                                {{
                                                    `${item.pubCampaignCode} - ${item.description}`
                                                }}
                                            </template>
                                            <template #selection="{ item }">
                                                {{
                                                    `${item.pubCampaignCode} - ${item.description}`
                                                }}
                                            </template>
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <ValidationProvider
                                        name="Day"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <v-autocomplete
                                            :error-messages="errors[0]"
                                            :items="days"
                                            clearable
                                            item-value="day"
                                            item-text="day"
                                            label="Day"
                                            outlined
                                            v-model="day"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider
                                        name="Month"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <v-autocomplete
                                            :error-messages="errors[0]"
                                            :items="months"
                                            clearable
                                            item-value="id"
                                            item-text="mth"
                                            label="Month"
                                            outlined
                                            v-model="month"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider
                                        name="Year"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <v-autocomplete
                                            :error-messages="errors[0]"
                                            :items="years"
                                            clearable
                                            item-value="year"
                                            item-text="year"
                                            label="Year"
                                            outlined
                                            v-model="year"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card>

                        <div class="button-bar right">
                            <v-btn
                                color="primary"
                                :disabled="invalid || isLoading"
                                :loading="isLoading"
                                @click="createCC"
                            >
                                <template #loader>
                                    <span class="custom-loader">
                                        <v-icon light>mdi-cached</v-icon>
                                    </span>
                                </template>

                                Continue

                                <v-icon right>
                                    mdi-step-forward
                                </v-icon>
                            </v-btn>
                        </div>
                    </ValidationObserver>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <ValidationObserver ref="sTwoObserver" v-slot="{ invalid }">
                        <v-card class="mb-12" style="margin-top: 10px;">
                            <ValidationProvider
                                name="Tune Offer URL"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-autocomplete
                                    :error-messages="errors[0]"
                                    :items="tuneOfferUrlsArr"
                                    clearable
                                    item-value="id"
                                    item-text="urlName"
                                    label="Tune Offer URL"
                                    outlined
                                    v-model="tuneOfferUrlId"
                                />
                            </ValidationProvider>

                            <v-row>
                                <v-col>
                                    <ValidationProvider
                                        name="Affiliate ID"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <v-autocomplete
                                            :error-messages="errors[0]"
                                            :items="affiliatesArr"
                                            clearable
                                            item-value="id"
                                            item-text="name"
                                            label="Affiliate ID"
                                            outlined
                                            v-model="affiliateId"
                                        >
                                            <template #item="{ item }">
                                                {{
                                                    `${item.id} - ${item.name}`
                                                }}
                                            </template>
                                            <template #selection="{ item }">
                                                {{
                                                    `${item.id} - ${item.name}`
                                                }}
                                            </template>
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col>
                                    <ValidationProvider
                                        name="Tune Domain"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                        <v-autocomplete
                                            :error-messages="errors[0]"
                                            :items="domainsArr"
                                            clearable
                                            item-value="id"
                                            item-text="tuneDomain"
                                            label="Tune Domain"
                                            outlined
                                            v-model="tuneDomainId"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card>

                        <div class="button-bar between">
                            <v-btn color="teal" outlined @click="e1 = 1">
                                <v-icon right>
                                    mdi-step-backward
                                </v-icon>

                                <div class="back-buton">
                                    Go back
                                </div>
                            </v-btn>

                            <v-btn
                                :disabled="invalid || isLoading"
                                :loading="isLoading"
                                color="primary"
                                @click="stepTwo"
                            >
                                <template #loader>
                                    <span class="custom-loader">
                                        <v-icon light>mdi-cached</v-icon>
                                    </span>
                                </template>

                                Continue

                                <v-icon right>
                                    mdi-step-forward
                                </v-icon>
                            </v-btn>
                        </div>
                    </ValidationObserver>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <ValidationObserver
                        ref="sThreeObserver"
                        v-slot="{ invalid }"
                    >
                        <v-card class="mb-12 step3-card">
                            <v-row>
                                <v-col>
                                    <h1 v-if="areThereFixVals" class="fixed-values">
                                        Fixed Values
                                    </h1>
                                    <div ref="newUtmFields" class="utm-fields" />
                                </v-col>
                            </v-row>

                            <v-row
                                v-for="(n, idx) in affUI.length / 2"
                                :key="idx"
                                align="end"
                                justify="space-around"
                            >
                                <v-col cols="6" ref="col1">
                                    <utm-field
                                        v-model="
                                            affParams[
                                                `affSub${
                                                    idx !== 0 ? idx + 1 : ''
                                                }`
                                            ]
                                        "
                                        :disabled="affUI[idx].disabled"
                                        :dynamically-created="
                                            affUI[idx].value !== undefined
                                        "
                                        :items="
                                            !affUI[idx].value
                                                ? getVariablesArr(
                                                    affUI[idx].label
                                                )
                                                : []
                                        "
                                        :label="affUI[idx].label"
                                        :name="affUI[idx].name"
                                        :rules="affUI[idx].rules"
                                        :value="affUI[idx].value"
                                        item-value="variableValue"
                                        item-text="variableValue"
                                    />
                                </v-col>
                                <v-col cols="6" ref="col2">
                                    <utm-field
                                        v-model="
                                            affParams[`affUnique${idx + 1}`]
                                        "
                                        :disabled="affUI[idx + 5].disabled"
                                        :dynamically-created="
                                            affUI[idx + 5].value !== undefined
                                        "
                                        :items="
                                            !affUI[idx + 5].value
                                                ? getVariablesArr(
                                                    affUI[idx + 5].label
                                                )
                                                : []
                                        "
                                        :label="affUI[idx + 5].label"
                                        :name="affUI[idx + 5].name"
                                        :rules="affUI[idx + 5].rules"
                                        :value="affUI[idx + 5].value"
                                        item-value="variableValue"
                                        item-text="variableValue"
                                    />
                                </v-col>
                            </v-row>
                        </v-card>

                        <div class="button-bar between">
                            <v-btn color="teal" outlined @click="e1 = 2">
                                <v-icon right>
                                    mdi-step-backward
                                </v-icon>

                                <div class="back-buton">
                                    Go back
                                </div>
                            </v-btn>

                            <v-btn
                                :disabled="invalid || isLoading"
                                :loading="isLoading"
                                color="primary"
                                @click="stepThree"
                            >
                                <template #loader>
                                    <span class="custom-loader">
                                        <v-icon light>mdi-cached</v-icon>
                                    </span>
                                </template>

                                Save

                                <v-icon right>
                                    mdi-content-save-outline
                                </v-icon>
                            </v-btn>
                        </div>
                    </ValidationObserver>
                </v-stepper-content>

                <v-stepper-content step="4">
                    <div class="finalStep">
                        <div>
                            Campaign Completed
                        </div>

                        <p>
                            The Generate URL is:
                        </p>

                        <div>
                            <clipboard-icon
                                :value="
                                    finalUrl
                                        ? decodeURIComponent(finalUrl.href)
                                        : ''
                                "
                            />
                        </div>
                    </div>
                    <v-card class="d-flex justify-space-between mb-6" flat tile>
                        <v-btn color="teal" outlined @click="e1 = 3">
                            Edit Campaign
                        </v-btn>

                        <v-btn color="green" @click="goToCampaignList">
                            Complete
                        </v-btn>
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-col>
</template>

<script>
    import UtmField from './UtmField.vue'
    import ClipboardIcon from './ClipboardIcon'
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { KEEP_EDITED_ITEM, SET_EDITED_ITEM } from '../store/constants'

    export default {
        components: { UtmField, ClipboardIcon },

        data: () => ({
            componentsArr: [],
            defaultItem: {},
            e1: 0,
            offerIdFilter: undefined,
            areThereFixVals: false,
            touParams: {},

            affParams: {
                affSub: undefined,
                affSub2: undefined,
                affSub3: undefined,
                affSub4: undefined,
                affSub5: undefined,
                affUnique1: undefined,
                affUnique2: undefined,
                affUnique3: undefined,
                affUnique4: undefined,
                affUnique5: undefined,
            },

            affUI: [],

            days: Array.from({ length: 31 }, (item, i) => {
                return { day: i + 1 }
            }),

            months: Array.from({ length: 12 }, (item, i) => {
                return {
                    id: i + 1,
                    mth: new Date(0, i).toLocaleString('en-US', { month: 'short' }),
                }
            }),

            // From 2020 to 2040
            years: Array.from({ length: 21 }, (item, i) => i + 2020),

            finalUrl: undefined,
        }),

        computed: {
            ...mapGetters({
                affiliates: ['getTuneAffiliates'],
                campaignCodes: ['getCampaignCodes'],
                contentCodes: ['getContentCodes'],
                domains: ['getDomains'],
                getStateAttr: ['getStateAttr'],
                isLoading: ['isLoading'],
                pubCampaigns: ['getPublisherCampaigns'],
                pubCodes: ['getPubCodes'],
                sources: ['getSources'],
                tuneOffers: ['getTuneOffers'],
                tuneOfferUrls: ['getTuneOfferUrls'],
                urlTemplates: ['getUrlTemplates'],
                user: ['getUser'],
                urlVariables: ['getUrlVariables'],
                urlVariableTypes: ['getUrlVariableTypes'],
            }),

            allCampaignCodes() {
                return this.campaignCodes
            },

            allPubCodes() {
                return this.pubCodes
            },

            allPubCampaigns() {
                return this.pubCampaigns
            },

            allSources() {
                return this.sources
            },

            editedItem() {
                return this.getStateAttr('editedItem')
            },

            mappedVariables() {
                return this.urlVariables.map((u) => {
                    return {
                        id: u.id,
                        variableValue: u.variableValue,
                        variableTypeId: u.variableType.id,
                        shortValue: u.shortValue,
                        variableTypeValue: this.urlVariableTypeValue(
                            u.variableType.id
                        )?.variableType,
                        filterId: u.filterId,
                    }
                })
            },

            userId: {
                get() {
                    return this.editedItem?.userId || ''
                },

                set(value) {
                    this.updateEditedItem({ attr: 'userId', value })
                },
            },

            day: {
                get() {
                    return this.editedItem?.day || ''
                },

                set(_value) {
                    const value = parseInt(_value)
                    this.updateEditedItem({ attr: 'day', value })
                },
            },

            month: {
                get() {
                    return this.editedItem?.month || ''
                },

                set(_value) {
                    const value = parseInt(_value)
                    this.updateEditedItem({ attr: 'month', value })
                },
            },

            year: {
                get() {
                    return this.editedItem?.year || ''
                },

                set(_value) {
                    const value = parseInt(_value)
                    this.updateEditedItem({ attr: 'year', value })
                },
            },

            pubCodeId: {
                get() {
                    return this.editedItem?.pubCodeId
                        ? this.editedItem?.pubCodeId
                        : this.editedItem?.pubCode?.id
                },

                set(value) {
                    this.updateEditedItem({ attr: 'pubCodeId', value })
                },
            },

            pubCampaignId: {
                get() {
                    return this.editedItem?.pubCampaignId
                        ? this.editedItem?.pubCampaignId
                        : this.editedItem?.pubCampaign?.id
                },

                set(value) {
                    this.updateEditedItem({ attr: 'pubCampaignId', value })
                },
            },

            channelId: {
                get() {
                    return this.editedItem?.channelId
                        ? this.editedItem?.channelId
                        : this.editedItem?.channel?.id //??
                },

                set(value) {
                    this.updateEditedItem({ attr: 'channelId', value })
                },
            },

            sourceId: {
                get() {
                    return this.editedItem.sourceId || this.editedItem.source.id
                },

                set(value) {
                    this.updateEditedItem({ attr: 'sourceId', value })
                },
            },

            offerId: {
                get() {
                    return this.editedItem?.offerId || ''
                },

                set(value) {
                    this.updateEditedItem({ attr: 'offerId', value })
                },
            },

            affiliateId: {
                get() {
                    return (
                        this.editedItem?.affiliateId ||
                        Number(this.user?.affiliateId) ||
                        ''
                    )
                },

                set(value) {
                    this.updateEditedItem({ attr: 'affiliateId', value })
                },
            },

            tuneOfferUrlId: {
                get() {
                    return this.editedItem?.tuneOfferUrlId
                        ? this.editedItem?.tuneOfferUrlId
                        : this.editedItem.tuneOfferUrl
                            ? this.editedItem.tuneOfferUrl.id
                            : ''
                },

                set(value) {
                    this.updateEditedItem({ attr: 'tuneOfferUrlId', value })
                },
            },

            tuneDomainId: {
                get() {
                    return this.editedItem?.tuneDomainId
                        ? this.editedItem?.tuneDomainId
                        : this.editedItem.tuneDomain
                            ? this.editedItem.tuneDomain.id
                            : ''
                },

                set(value) {
                    this.updateEditedItem({ attr: 'tuneDomainId', value })
                },
            },

            affiliatesArr() {
                return this.affiliates
            },

            tuneOfferUrlsArr() {
                const _pubCodeId = this.pubCodeId
                    ? this.pubCodeId
                    : this.editedItem.pubCode?.id

                let touArr = _pubCodeId
                    ? this.tuneOfferUrls.filter(
                        (tou) => tou.source.id === this.sourceId && tou.contentCode?.pubCode?.id === _pubCodeId
                    )
                    : this.tuneOfferUrls

                return touArr
            },

            domainsArr() {
                const _dom = this.domains

                return _dom.sort((a, b) => (a.tuneDomain > b.tuneDomain ? 1 : -1))
            },

            touUrlTemplate() {
                const tof = this.tuneOfferUrls.find(
                    (tou) => tou.id === this.tuneOfferUrlId
                )

                return tof.source.tuneTemplateUrl
            },
        },

        methods: {
            ...mapMutations(['changeState']),
            ...mapActions([
                'updateCampaignCode',
                'createCampaignCode',
                'fetchDomains',
                'fetchPubCodes',
                'fetchSources',
                'fetchTuneOffers',
                'fetchTuneOfferUrls',
                'fetchUrlVariables',
                'fetchUrlVariableTypes',
                'updateEditedItem',
                'fetchPublisherCampaigns',
                'fetchTuneAffiliates',
            ]),

            initialize() {
                this.fetchDomains()
                this.fetchPubCodes()
                this.fetchPublisherCampaigns()
                this.fetchSources()
                this.fetchTuneAffiliates()
                this.fetchTuneOffers()
                this.fetchTuneOfferUrls()
                this.fetchUrlVariableTypes()
                this.fetchUrlVariables()
            },

            urlVariableTypeValue(urlVariableTypeID) {
                if (this.urlVariableTypes.length) {
                    return this.urlVariableTypes.find(
                        (u) => u.id === urlVariableTypeID
                    )
                }
            },

            getVariablesArr(variableTypeValue) {
                return variableTypeValue
                    ? this.mappedVariables
                        .filter((u) => u.variableTypeValue === variableTypeValue)
                        .sort((a, b) => a.variableValue > b.variableValue ? 1 : -1
                        )
                    : []
            },

            async createCC() {
                if (await this.$refs.sOneObserver.validate()) {
                    if (this.editedItem.id) {
                        this.e1++
                    } else {
                        this.createCampaignCode()
                    }
                }
            },

            async stepTwo() {
                this.resetStep3Columns()

                this.affiliateId = this.editedItem.affiliateId
                    ? this.editedItem.affiliateId
                    : this.user?.affiliateId

                await this.updateCampaignCode()

                const tou = this.tuneOfferUrls.find(
                    (tou) => tou.id === this.tuneOfferUrlId
                )

                Object.entries(tou.source).forEach(([param, initValue]) => {
                    if(param.includes('aff')) {
                        const snakeCase = param.replace(/[A-Z]/g,(l) => `_${l.toLowerCase()}`)
                        const value = typeof initValue === 'string' ? initValue.replace(/\[|\]/g, '') : initValue

                        let _tmp = undefined
                        let aValue = undefined

                        switch (value) {
                        case 'fbc_id':
                        case 'gclid':
                            _tmp = this.urlVariables.find(
                                (uv) => uv.variableType.variableType === value
                            )
                            aValue = _tmp ? _tmp.variableValue : undefined
                            break
                        case 'source':
                            aValue = tou.source.sourceShortCode
                            break
                        case 'content_code':
                            aValue = tou.contentCode.contentCode
                            break
                        case 'campaign_code':
                            aValue = this.editedItem.campaignCode
                            break
                        }

                        // If a parameter has a fixed string, we create
                        // a text field enabled so the user can change it
                        if ( initValue && !initValue.match(/\[|\]/g)?.length ) {
                            aValue = value
                        }

                        this.affParams[param] = aValue

                        this.affUI.push({
                            name: snakeCase,
                            label: initValue ? initValue.match(/\[|\]/g) ? value : null : null,
                            model: param,
                            value: aValue,
                            disabled: aValue !== undefined && aValue !== null,
                            rules: '',
                        })
                    }
                })

                // Sort the UI array
                this.affUI.sort((a, b) => {
                    if(a.model < b.model) {
                        return -1
                    }
                })

            },

            resetStep3Columns() {
                // Reset the UI final array
                this.affUI.splice(0, this.affUI.length)

                if (this.$refs.col1 && this.$refs.col2) {
                    this.$refs.col1.splice(0, this.$refs.col1.length)
                    this.$refs.col2.splice(0, this.$refs.col2.length)
                }
            },

            stepThree() {
                const domain = this.domains.find((d) => d.id === this.tuneDomainId)
                    .tuneDomain
                const _tou = this.tuneOfferUrls.find(
                    (tou) => tou.id === this.tuneOfferUrlId
                )
                const cc = this.contentCodes.find(
                    (cc) => cc.pubCode.id === this.editedItem.pubCode.id
                )

                const finalParams = {
                    offer_id: cc.tuneOfferId,
                    aff_id: this.affiliateId,
                    url_id: _tou.tuneOfferUrlId,
                    ...this.affParams,
                }

                this.finalUrl = new URL(`https://${domain}/aff_c`)

                Object.entries(finalParams).forEach(([key, value]) => {
                    if (value) {
                        key = key.replace(/[A-Z]/g, (l) => `_${l.toLowerCase()}`)
                        this.finalUrl.searchParams.append(key, value)
                    }
                })

                this.changeState({
                    type: SET_EDITED_ITEM,
                    payload: {
                        ...this.editedItem,
                        ...this.affParams,
                        generatedUrl: decodeURIComponent(this.finalUrl.href),
                    },
                })

                this.updateCampaignCode()
            // this.fakeSave()
            },

            decodeString(str) {
                return decodeURIComponent(str).replace(/\[|\]/g, '')
            },

            goToCampaignList() {
                this.$router.push('campaign-urls')
            },
        },

        watch: {
            async isLoading(newVal) {
                if (!newVal) {
                    this.e1 += 1
                }
            },
        },

        created() {
            if (!this.$store.getters.isEditedItemPreserved) {
                this.defaultItem = {
                    userId: this.user ? this.user.userId : -1,
                    channelId: undefined,
                    pubCodeId: undefined,
                    pubCampaignId: undefined,
                    day: undefined,
                    month: undefined,
                    year: undefined,
                    tuneOfferUrlId: undefined,
                    sourceId: -1,
                    tuneDomainId: undefined,
                }

                this.changeState({
                    type: SET_EDITED_ITEM,
                    payload: this.defaultItem,
                })
            } else {
                this.changeState({ type: KEEP_EDITED_ITEM, payload: false })
            }

            this.initialize()
        },
    }
</script>

<style lang="scss" scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none !important;
}

.custom-loader {
    animation: loader 1s infinite;
    display: flex;
}
@-moz-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@-o-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

.step3-card {
    padding-top: 10px;
}

.fixed-values {
    text-align: center;
}

.back-buton {
    margin-left: 10px;
}

.utm-fields, .button-bar {
    display: flex;
}

.button-bar.right {
    justify-content: flex-end;
}

.button-bar.between {
    justify-content: space-between;
}

.list-group-item {
    margin-bottom: 0;
    margin-top: 15px;
    width: 50%;
}

.finalStep {
    align-items: center;
    display: flex;
    flex-direction: column;

    & div:first-child {
        font-size: 4rem;
        text-align: center;
    }

    & div:last-child {
        font-size: 1em;
        margin-bottom: 30px;
        padding: 10px;
        text-align: left;
        width: 100%;
    }

    & p {
        font-size: 1.5rem;
        text-align: center;
        width: 100%;
    }
}
</style>
