var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('crud-table',{attrs:{"table":_vm.tableName,"headers":_vm.headers,"items":_vm.items,"obj-template":_vm.defaultItem,"item-name":"Publisher Campaign"},on:{"onDelete":_vm.deletePC,"onSave":_vm.savePC},scopedSlots:_vm._u([{key:"newItemForm",fn:function(){return [_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Campaign Code","rules":"required|max:3|alpha_dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Campaign Code","outlined":"","error-messages":errors[0]},model:{value:(_vm.pubCampaignCode),callback:function ($$v) {_vm.pubCampaignCode=$$v},expression:"pubCampaignCode"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Product Slug","rules":"required|alpha_dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Product Slug","outlined":"","required":"","error-messages":errors[0]},model:{value:(_vm.productSlug),callback:function ($$v) {_vm.productSlug=$$v},expression:"productSlug"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Asana Project Link","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Asana Project Link","outlined":"","required":"","error-messages":errors[0]},model:{value:(_vm.asanaProjectLink),callback:function ($$v) {_vm.asanaProjectLink=$$v},expression:"asanaProjectLink"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Description","outlined":"","required":"","rows":"5","error-messages":errors[0]},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }