<template>
    <crud-table
        :table="tableName"
        :headers="headers"
        :items="items"
        :obj-template="defaultItem"
        item-name="Content Code"
        @onDelete="deleteCC"
        @onSave="saveCC"
    >
        <template #newItemForm>
            <v-col cols="10">
                <ValidationProvider
                    name="Content Code"
                    rules="required|alpha_dash"
                    v-slot="{ errors }"
                >
                    <v-text-field
                        :error-messages="errors[0]"
                        label="Content Code"
                        outlined
                        v-model="contentCode"
                    />
                </ValidationProvider>

                <ValidationProvider
                    name="Offer ID"
                    rules="required"
                    v-slot="{ errors }"
                >
                    <v-autocomplete
                        :error-messages="errors[0]"
                        :filter="tuneOfferIdFilter"
                        :items="tuneOffersArr"
                        auto-select-first
                        clearable
                        item-value="id"
                        item-text="name"
                        label="Tune Offer ID"
                        outlined
                        v-model="tuneOfferId"
                    >
                        <template #item="{ item }">
                            {{ `${item.id} - ${item.name}` }}
                        </template>
                        <template #selection="{ item }">
                            {{ `${item.id} - ${item.name}` }}
                        </template>
                    </v-autocomplete>
                </ValidationProvider>

                <ValidationProvider
                    name="Pub Code"
                    rules="required"
                    v-slot="{ errors }"
                >
                    <v-autocomplete
                        :error-messages="errors[0]"
                        :filter="pubCodesFilter"
                        :items="pubCodesArr"
                        auto-select-first
                        clearable
                        item-value="id"
                        item-text="pubCode"
                        label="Pub Code"
                        outlined
                        v-model="pubCodeId"
                    >
                        <template #item="{ item }">
                            {{ `${item.pubCode} - ${item.productSlug}` }}
                        </template>
                        <template #selection="{ item }">
                            {{ `${item.pubCode} - ${item.productSlug}` }}
                        </template>
                    </v-autocomplete>
                </ValidationProvider>

                <ValidationProvider
                    name="Descriptive Name"
                    rules="required"
                    v-slot="{ errors }"
                >
                    <v-text-field
                        :error-messages="errors[0]"
                        label="Descriptive Name"
                        outlined
                        v-model="descriptiveName"
                    />
                </ValidationProvider>

                <ValidationProvider
                    name="Base URL"
                    rules="required|url"
                    v-slot="{ errors }"
                >
                    <v-text-field
                        :error-messages="errors[0]"
                        label="Base URL"
                        outlined
                        v-model="baseUrl"
                    />
                </ValidationProvider>
            </v-col>
        </template>
    </crud-table>
</template>

<script>
    import CrudTable from './CrudTable.vue'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        components: { CrudTable },

        data: () => ({
            defaultItem: {
                id: -1,
                contentCode: undefined,
                tuneOfferId: -1,
                pubCodeId: -1,
                descriptiveName: undefined,
                baseUrl: undefined,
            },

            headers: [
                { text: 'ID', value: 'id', align: 'start', sortable: false },
                { text: 'Content Code', value: 'contentCodeLbl', sortable: true },
                { text: 'Tune Offer', value: 'tuneOfferLbl', sortable: true },
                { text: 'Pub Code', value: 'pubCodeLbl', sortable: true },
                { text: 'Descriptive Name', value: 'descriptiveName', sortable: true },
                { text: 'URL', value: 'baseUrl' },
            ],

            actions: { text: 'Actions', value: 'actions', sortable: false },

            tableName: 'Content Codes',
        }),

        computed: {
            ...mapGetters([
                'getContentCodes',
                'getPubCodes',
                'getTuneOffers',
                'getStateAttr',
            ]),

            items() {
                return this.getContentCodes.map((cc) => {
                    const to = this.tuneOffersArr.find(
                        (to) => to.id === cc.tuneOfferId
                    )
                    const pc = this.getPubCodes.find(
                        (pc) => pc.id === cc.pubCode.id
                    )

                    return {
                        ...cc,
                        contentCodeLbl: cc.contentCode,
                        pubCodeLbl: cc.pubCode.pubCode,
                        tuneOfferLbl: to ? `${to.id} - ${to.name}` : undefined,
                    }
                })
            },

            editedItem() {
                return this.getStateAttr('editedItem')
            },

            tuneOffersArr() {
                return this.getTuneOffers
            },

            contentCode: {
                get() {
                    return this.editedItem.contentCode
                },

                set(value) {
                    this.updateEditedItem({ attr: 'contentCode', value })
                },
            },

            tuneOfferId: {
                get() {
                    return this.editedItem.tuneOfferId
                },

                set(value) {
                    this.updateEditedItem({ attr: 'tuneOfferId', value })
                },
            },

            pubCodesArr() {
                return this.getPubCodes
            },

            pubCodeId: {
                get() {
                    return this.editedItem.pubCodeId
                        ? this.editedItem.pubCodeId
                        : this.editedItem.pubCode.id
                },

                set(value) {
                    this.updateEditedItem({ attr: 'pubCodeId', value })
                },
            },

            descriptiveName: {
                get() {
                    return this.editedItem.descriptiveName
                },

                set(value) {
                    this.updateEditedItem({ attr: 'descriptiveName', value })
                },
            },

            baseUrl: {
                get() {
                    return this.editedItem.baseUrl
                },

                set(value) {
                    this.updateEditedItem({ attr: 'baseUrl', value })
                },
            },
        },

        methods: {
            ...mapActions([
                'fetchTuneOffers',
                'fetchPubCodes',
                'createContentCode',
                'updateContentCode',
                'deleteContentCode',
                'updateEditedItem',
                'setTablePermissions',
            ]),

            initialize() {
                this.fetchTuneOffers()
                this.fetchPubCodes()

                if (this.$isAdmin()) {
                    this.headers.push(this.actions)
                    this.setTablePermissions()
                } else {
                    this.setTablePermissions({ add: true, edit: false })
                }
            },

            saveCC() {
                const { id } = this.editedItem

                const pc = this.pubCodesArr.find(
                    (pc) => pc.id === this.editedItem.pubCodeId
                )
                this.updateEditedItem({ attr: 'pubCode', value: pc })

                if (id > -1) {
                    this.updateContentCode()
                } else {
                    this.createContentCode()
                }
            },

            deleteCC() {
                this.deleteContentCode()
            },

            tuneOfferIdFilter(item, queryText) {
                const searchText = queryText.toLowerCase()

                const id = String(item.id)
                const name = item.name.toLowerCase()

                return id.indexOf(searchText) > -1 || name.indexOf(searchText) > -1
            },

            pubCodesFilter(item, queryText) {
                const searchText = queryText.toLowerCase()

                const pubCode = item.pubCode.toLowerCase()
                const productSlug = item.productSlug.toLowerCase()

                return pubCode.indexOf(searchText) > -1 || productSlug.indexOf(searchText) > -1
            },
        },

        created() {
            this.initialize()
        },
    }
</script>

<style>
.content-code {
    display: flex;
    align-items: center;
}
</style>
