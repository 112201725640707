<template>
    <div style="display: flex; align-items: center;">
        <v-tooltip
            bottom
            :color="color"
            right
        >
            <template #activator="{ on, attrs }">
                <v-icon
                    :color="color"
                    class="mr-2"
                    style="cursor: pointer"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="copyToClipboard(value)"
                >
                    mdi-content-copy
                </v-icon>
            </template>
            <span>{{ copied ? 'Copied' : 'Copy' }}</span>
        </v-tooltip>

        <div>
            {{ value ? value : '' }}
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
                default: null,
            },
        },

        data: () => ({
            copied: false,
            color: 'gray',
        }),

        methods: {
            copyToClipboard(string) {
                navigator.clipboard.writeText(string)
                this.color = 'success'
                this.copied = true

                setTimeout(() => {
                    this.color = 'gray'
                    this.copied = false
                }, 1200)
            },
        },
    }
</script>

<style>

</style>