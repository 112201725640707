export default {
    getToken: state => state.token,
    isAuthenticated: state => state.isAuthenticated,
    isAdmin: state => state.user.isAdmin,
    isLoading: state => state.loading,
    isEditedItemPreserved: state => state.keepEditedItem,

    //Generic Getter
    getStateAttr: state => attrName => state[attrName],

    //Pub Codes
    getPubCodes: state => state.pubCodes,

    //Pub Codes
    getPublisherCampaigns: state => state.publisherCampaigns,

    //Content Codes
    getContentCodes: state => state.contentCodes,

    //Sources
    getSources: state => state.sources,

    //URL Templates
    getUrlTemplates: state => state.urlTemplates,

    //Table Permissions
    getTablePermissions: state => state.tablePermissions,

    //URL Variables & Types
    getURLVariableByType: state => variableType => state.urlVariables.filter( urlVariable => urlVariable.variable_type_id === variableType ),
    getUrlVariableTypes: state => state.urlVariableTypes,
    getUrlVariables: state => state.urlVariables,
    getUrlVariablesByTypeId: state => id => id ? state.urlVariables.filter(v => v.variableType.id === id) : state.urlVariables,

    getUrlVariablesOfTypeUtmSource: state => state.urlVariables.filter(v => v.variableType.id === 1),

    //Campaign Codes
    getCampaignCodes: state => (state.campaignCodes || []),

    //Tune Offer URLs
    getDomains: state => state.domains,

    getTuneAffiliates: state => state.affiliates,

    //Tune Offer URLs
    getTuneOfferUrls: state => {
        return state.tuneOfferUrls.map( tou => {
            const cc = state.contentCodes.find( cc => cc.id === +tou.utmContent?.id )
            const pb = cc
                ? state.pubCodes.find( pb => pb.id === cc.pubCode.id )
                : undefined

            return { ...tou, pubCode: pb?.pubCode, contentCodeLbl: cc?.contentCode }
        })
    },

    // Tune Offer IDs
    getTuneOffers: state => state.tuneOffers,

    // Campaign Urls
    getCampaignUrls: state => state.campaignUrls,

    // Users
    getUsers: state => state.users,
    getUser: state => {
        const sessionUsrObj = JSON.parse(sessionStorage.userData)

        if(!state.user && sessionUsrObj) {
            state.user = sessionUsrObj
        }

        return state.user
    },
}
