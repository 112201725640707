import Vue from 'vue'
import router from './router'
import store from './store'
import VueAxios from 'vue-axios'
import VueAuth from '@websanova/vue-auth/src/v2.js'
import driverAuthBearer from './util/amazon-bearer'
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js'
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js'
import axios from './util/axios'
import IsAdminPlugin from './plugins/isAdmin'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import { required, max, alpha_num, alpha, alpha_spaces, numeric, alpha_dash } from 'vee-validate/dist/rules'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

Vue.config.productionTip = false

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('required', {
    ...required,
    message: '{_field_} is required',
})

extend('numeric', {
    ...numeric,
    message: '{_field_} must be numeric',
})

extend('alpha_num', {
    ...alpha_num,
    message: '{_field_} must be alpha numeric',
})

extend('alpha_dash', {
    ...alpha_dash,
    message: '{_field_} must be alpha numeric:dash',
})

extend('alpha_spaces', {
    ...alpha_spaces,
    message: '{_field_} must be alpha numeric:spaces',
})

extend('alpha', {
    ...alpha,
    message: '{_field_} must be a string without numbers',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})

extend('url', {
    validate(value) {
        if (value) {
            var pattern = new RegExp(
                '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

            return pattern.test(value)
        }

        return false
    },
    message: 'This value must be a valid URL',
})


Vue.use(IsAdminPlugin, store)
Vue.use(VueAxios, axios)
Vue.use(VueAuth, {
    plugins: {
        http: axios,
        router: router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios,
        router: driverRouterVueRouter,
    },
    options: {
        rolesKey: 'type',
        notFoundRedirect: { name: 'login' },
        fetchData: { enabled: false },
        refreshData: { url: process.env.VUE_APP_SSO_REFRESH_LAMBDA },
    },
})

new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    render: (h) => h(App),
})
