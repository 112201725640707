<template>
    <div class="container-login">
        <div class="loader-container">
            <div class="loader">
                Loading...
            </div>
        </div>
        <div class="message-container">
            <h3> {{ message }} </h3>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            message: {
                type: String,
                default: 'Loading...',
            },
        },
    }
</script>

<style lang="css">
.container-login {
    width: 100%;
    display: grid;
    overflow: hidden;
}
.message-container {
    display: flex;
    justify-content: center;
}
.loader-container {
    width: 11em;
    height: 11em;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #3caeff ;
  background: -moz-linear-gradient(left, #3caeff 30%, rgba(118, 215, 237, 0.37) 42%);
  background: -webkit-linear-gradient(left, #3caeff 30%, rgba(118, 215, 237, 0.37) 42%);
  background: -o-linear-gradient(left, #3caeff 30%, rgba(118, 215, 237, 0.37) 42%);
  background: -ms-linear-gradient(left, #3caeff 30%, rgba(118, 215, 237, 0.37) 42%);
  background: linear-gradient(to right, #3caeff 30%, rgba(118, 215, 237, 0.37) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #FFF;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>