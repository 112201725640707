<template>
    <div class="container-home">
        <div class="home-dashboard">
            <content-codes-table />
        </div>
    </div>
</template>

<script>
    import ContentCodesTable from '../components/ContentCodes.vue'

    export default {
        components: { ContentCodesTable },
    }
</script>