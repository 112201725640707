<template>
    <crud-table
        :table="tableName"
        :headers="headers"
        :items="items"
        :obj-template="defaultItem"
        @onDelete="deleteUVT"
        @onSave="saveUVT"
        item-name="Type"
        sort-by="id"
        class="elevation-1"
    >
        <template #newItemForm>
            <v-col
                cols="12"
                sm="8"
                md="6"
            >
                <ValidationProvider
                    name="Variable Type"
                    rules="required|max:45|alpha_dash"
                    v-slot="{ errors }"
                >
                    <v-text-field
                        :error-messages="errors[0]"
                        label="Variable Type"
                        outlined
                        v-model="variableType"
                    />
                </ValidationProvider>

                <ValidationProvider
                    name="Is Locked"
                    rules="required"
                    v-slot="{ errors }"
                >
                    <v-select
                        :error-messages="errors[0]"
                        :items="isLockedItems"
                        :item-text="isLockedItems.text"
                        :item-value="isLockedItems.value"
                        label="Is Variable Locked?"
                        outlined
                        v-model="isLocked"
                    />
                </ValidationProvider>
            </v-col>
        </template>
    </crud-table>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import CrudTable from './CrudTable.vue'

    export default {
        name: 'UrlVariableTypes',
        components: { CrudTable },

        data: () => ({
            defaultItem: {
                variableType: undefined,
                isLocked: false,
            },

            isLockedItems: [
                {
                    text: 'true',
                    value: true,
                },
                {
                    text: 'false',
                    value: false,
                },
            ],

            actions: { text: 'Actions', value: 'actions', sortable: false, align: 'end' },

            headers: [
                { text: 'ID', align: 'start', sortable: false, value: 'id' },
                { text: 'Name', value: 'variableType' },
                { text: 'IsLocked', value: 'isLocked' },
            ],

            tableName: 'URL Variable Types',

        }),

        computed: {
            ...mapGetters(['getUrlVariableTypes', 'getStateAttr']),

            items () {
                // return this.urlVariableTypes.map( u => {
                //     return {
                //         id: u.id,
                //         variableType: u.variableType,
                //         isLocked: u.isLocked ? u.isLocked : false,
                //     }
                // })
                return this.getUrlVariableTypes
            },

            editedItem () {
                return this.getStateAttr('editedItem')
            },

            variableType: {
                get() {
                    return this.editedItem.variableType
                },

                set(value) {
                    this.updateEditedItem({ attr: 'variableType', value })
                },
            },

            isLocked: {
                get() {
                    return this.editedItem.isLocked
                },

                set(value) {
                    this.updateEditedItem({ attr: 'isLocked', value })
                },
            },
        },

        methods: {
            ...mapActions([
                'fetchUrlVariableTypes', 'fetchUrlVariables', 'createUrlVariableTypes', 'updateUrlVariableTypes',
                'updateEditedItem', 'deleteUrlVariableTypes', 'setTablePermissions',
            ]),

            initialize () {
                this.fetchUrlVariableTypes()
                this.fetchUrlVariables()

                if(this.$isAdmin()) {
                    this.headers.push(this.actions)
                    this.setTablePermissions()
                } else {
                    this.setTablePermissions({ add: false, edit: false })
                }
            },

            saveUVT () {
                const { id } = this.editedItem

                if ( id > -1) {
                    this.updateUrlVariableTypes()
                } else {
                    this.createUrlVariableTypes()
                }
            },

            deleteUVT () {
                this.deleteUrlVariableTypes()
            },
        },

        created () {
            this.initialize()
        },

        updated () {
            this.fetchUrlVariableTypes()
            this.fetchUrlVariables()
        },
    }
</script>