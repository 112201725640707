<template>
    <crud-table
        :edit-and-redirect="{ name: 'create-campaign' }"
        :headers="headers"
        :items="campaignUrlItems"
        :obj-template="defaultItem"
        :table="tableName"
        :view-delete-actn="false"
        sort-by="id"
        class="elevation-1"
        view-details
    >
        <template #details>
            <v-simple-table>
                <template #default>
                    <tbody id="detailsBody">
                        <tr>
                            <td>ID</td>
                            <td>{{ editedItem.id }}</td>
                        </tr>
                        <tr>
                            <td>User</td>
                            <td>{{ editedItem.user ? `${editedItem.user.firstName} ${editedItem.user.lastName}` : '' }}</td>
                        </tr>
                        <tr>
                            <td>Campaign Code</td>
                            <td>{{ editedItem.campaignCode }}</td>
                        </tr>
                        <tr>
                            <td>Affiliate ID</td>
                            <td>{{ editedItem.affiliateId }}</td>
                        </tr>
                        <tr>
                            <td>Generated Url</td>
                            <td>{{ editedItem.generatedUrl }}</td>
                        </tr>
                        <tr>
                            <td class="td-child">
                                Tune Offer URL ID
                            </td>
                            <td>{{ editedItem.tuneOfferUrl ? editedItem.tuneOfferUrl.id : '' }}</td>
                        </tr>
                        <tr>
                            <td class="td-child">
                                Name
                            </td>
                            <td>{{ editedItem.tuneOfferUrl ? editedItem.tuneOfferUrl.urlName : '' }}</td>
                        </tr>
                        <tr>
                            <td class="td-child">
                                Destination
                            </td>
                            <td>{{ editedItem.tuneOfferUrl ? editedItem.tuneOfferUrl.destinationUrl : '' }}</td>
                        </tr>
                        <tr>
                            <td class="td-child">
                                Tune Offer
                            </td>
                            <td>{{ editedItem.tuneOfferUrl ? getTuneOfferName(editedItem.tuneOfferUrl.tuneOfferId) : '' }}</td>
                        </tr>
                        <tr>
                            <td>Tune Domain</td>
                            <td>{{ editedItem.tuneDomain.tuneDomain }}</td>
                        </tr>
                        <tr>
                            <td>Channel</td>
                            <td>{{ editedItem.channel.shortValue }}</td>
                        </tr>
                        <tr>
                            <td>Pub Campaign Code</td>
                            <td>{{ editedItem.pubCampaign ? editedItem.pubCampaign.pubCampaignCode : '' }}</td>
                        </tr>
                        <tr>
                            <td>Pub Code</td>
                            <td>{{ editedItem.pubCode.pubCode }}</td>
                        </tr>
                        <tr>
                            <td class="td-child">
                                Product Slug
                            </td>
                            <td>{{ editedItem.pubCode.productSlug }}</td>
                        </tr>
                        <tr>
                            <td>AffSub 1</td>
                            <td>{{ editedItem.affSub }}</td>
                        </tr>
                        <tr>
                            <td>AffSub 2</td>
                            <td>{{ editedItem.affSub2 }}</td>
                        </tr>
                        <tr>
                            <td>AffSub 3</td>
                            <td>{{ editedItem.affSub3 }}</td>
                        </tr>
                        <tr>
                            <td>AffSub 4</td>
                            <td>{{ editedItem.affSub4 }}</td>
                        </tr>
                        <tr>
                            <td>AffSub 5</td>
                            <td>{{ editedItem.affSub5 }}</td>
                        </tr>
                        <tr>
                            <td>AffUnique 1</td>
                            <td>{{ editedItem.affUnique1 ? editedItem.affUnique1 : '' }}</td>
                        </tr>
                        <tr>
                            <td>AffUnique 2</td>
                            <td>{{ editedItem.affUnique2 ? editedItem.affUnique2 : '' }}</td>
                        </tr>
                        <tr>
                            <td>AffUnique 3</td>
                            <td>{{ editedItem.affUnique3 ? editedItem.affUnique3 : '' }}</td>
                        </tr>
                        <tr>
                            <td>AffUnique 4</td>
                            <td>{{ editedItem.affUnique4 ? editedItem.affUnique4 : '' }}</td>
                        </tr>
                        <tr>
                            <td>AffUnique 5</td>
                            <td>{{ editedItem.affUnique5 ? editedItem.affUnique5 : '' }}</td>
                        </tr>
                        <tr>
                            <td>Date Added</td>
                            <td>{{ editedItem.dateAdded | formatDate }}</td>
                        </tr>
                        <tr>
                            <td>Date Modified</td>
                            <td>{{ editedItem.dateModified | formatDate }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
    </crud-table>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import CrudTable from './CrudTable.vue'
    import moment from 'moment'

    export default {
        name: 'CampaignUrls',
        components: { CrudTable },
        filters: {
            formatDate (value) {
                return value ? moment(String(value)).format('DD-MM-YYYY') : ''
            },
        },

        data: () => ({
            defaultItem: {
                id: 0,
                user: '',
                campaignCode: '',
                tuneOfferUrl: '',
                tuneDomain: '',
                generatedUrl: '',
                pubCode: '',
                channel: '',
                month: '',
                year: 2020,
                dateAdded: '',
                dateModified: '',
            },

            headers: [
                { text: 'ID', align: 'start', sortable: true, value: 'id' },
                { text: 'Campaign Code', value: 'campaignCode', sortable: true },
                { text: 'Tune Offer URL', value: 'tuneOfferUrl[urlName]', sortable: true, width: '150px' },
                { text: 'Generated URL', value: 'generatedUrl', sortable: true, width: '200px' },
                { text: 'Pub Code', value: 'pubCode[pubCode]', sortable: true, align: 'center', width: '100px' },
                { text: 'Channel', value: 'channel.shortValue', sortable: true },
                { text: 'Month', value: 'month', sortable: true },
                { text: 'Year', value: 'year', sortable: true },
                { text: 'Date Added', value: 'dateAdded', sortable: true, width: '110px' },
                { text: 'Date Modified', value: 'dateModified', sortable: true, width: '110px' },
            ],

            actions: { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '100px' },

            tableName: 'Campaign URLs',
        }),

        computed: {
            ...mapGetters({
                campaignUrls: ['getCampaignUrls'],
                getStateAttr: ['getStateAttr'],
                tuneOffers: ['getTuneOffers'],
            }),

            editedItem () {
                return this.getStateAttr('editedItem')
            },

            campaignUrlItems () {
                return this.campaignUrls
            },
        },

        methods: {
            ...mapActions(['fetchTuneOffers', 'fetchCampaignUrls', 'setTablePermissions']),

            initialize () {
                this.fetchCampaignUrls()
                this.fetchTuneOffers()

                this.setTablePermissions({ add: false, edit: true })

                this.headers.push(this.actions)
            },

            getTuneOfferName(id) {
                const to = this.tuneOffers?.find(to => to.id === id)

                return to ? to.name : ''
            },
        },

        created () {
            this.initialize()
        },
    }
</script>

<style lang="scss">
    #detailsBody > tr > td {
        width: 150px !important;
    }
    #detailsBody > tr > td:last-child {
        max-width: 340px !important;
        width: 400px !important;
    }
    .td-child {
        text-align: right !important;
    }
</style>