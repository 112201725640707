<template>
    <div class="container-home">
        <div class="home-dashboard">
            <publisher-campaign />
        </div>
    </div>
</template>

<script>
    import PublisherCampaign from '../components/PublisherCampaign.vue'

    export default {
        components: { PublisherCampaign },
    }
</script>