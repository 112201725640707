var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center"},attrs:{"cols":"12"}},[_c('h1',{staticClass:"table-name"},[(!_vm.editedItem.id)?_c('span',[_vm._v("Create New ")]):_c('span',[_vm._v("Edit")]),_vm._v(" Campaign ")]),_c('v-stepper',{staticStyle:{"width":"100%"},model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 1,"step":"1"}},[_vm._v(" Step 1 ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 2,"step":"2"}},[_vm._v(" Step 2 ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 3,"step":"3"}},[_vm._v(" Step 3 ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"4"}},[_vm._v(" Done ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('ValidationObserver',{ref:"sOneObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"mb-12"},[_c('v-row',{staticStyle:{"margin-top":"10px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Source","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"items":_vm.allSources,"clearable":"","item-value":"id","label":"Source","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.sourceName) + " - " + (item.sourceShortCode)))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.sourceName) + " - " + (item.sourceShortCode)))+" ")]}}],null,true),model:{value:(_vm.sourceId),callback:function ($$v) {_vm.sourceId=$$v},expression:"sourceId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Channel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"items":_vm.getVariablesArr('utm_channel'),"clearable":"","item-value":"id","item-text":"variableValue","label":"Channel","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.variableValue) + " - " + (item.shortValue)))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.variableValue) + " - " + (item.shortValue)))+" ")]}}],null,true),model:{value:(_vm.channelId),callback:function ($$v) {_vm.channelId=$$v},expression:"channelId"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Pub Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"items":_vm.allPubCodes,"clearable":"","item-text":"pubCode","item-value":"id","label":"Pub Codes","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.pubCode) + " - " + (item.productSlug)))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.pubCode) + " - " + (item.productSlug)))+" ")]}}],null,true),model:{value:(_vm.pubCodeId),callback:function ($$v) {_vm.pubCodeId=$$v},expression:"pubCodeId"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Publisher Campaign","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"items":_vm.allPubCampaigns,"clearable":"","item-text":"pubCampaignCode","item-value":"id","label":"Publisher Campaign","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.pubCampaignCode) + " - " + (item.description)))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.pubCampaignCode) + " - " + (item.description)))+" ")]}}],null,true),model:{value:(_vm.pubCampaignId),callback:function ($$v) {_vm.pubCampaignId=$$v},expression:"pubCampaignId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Day","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"items":_vm.days,"clearable":"","item-value":"day","item-text":"day","label":"Day","outlined":""},model:{value:(_vm.day),callback:function ($$v) {_vm.day=$$v},expression:"day"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"items":_vm.months,"clearable":"","item-value":"id","item-text":"mth","label":"Month","outlined":""},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"items":_vm.years,"clearable":"","item-value":"year","item-text":"year","label":"Year","outlined":""},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"button-bar right"},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid || _vm.isLoading,"loading":_vm.isLoading},on:{"click":_vm.createCC},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,true)},[_vm._v(" Continue "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-step-forward ")])],1)],1)]}}])})],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('ValidationObserver',{ref:"sTwoObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"mb-12",staticStyle:{"margin-top":"10px"}},[_c('ValidationProvider',{attrs:{"name":"Tune Offer URL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"items":_vm.tuneOfferUrlsArr,"clearable":"","item-value":"id","item-text":"urlName","label":"Tune Offer URL","outlined":""},model:{value:(_vm.tuneOfferUrlId),callback:function ($$v) {_vm.tuneOfferUrlId=$$v},expression:"tuneOfferUrlId"}})]}}],null,true)}),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Affiliate ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"items":_vm.affiliatesArr,"clearable":"","item-value":"id","item-text":"name","label":"Affiliate ID","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.id) + " - " + (item.name)))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.id) + " - " + (item.name)))+" ")]}}],null,true),model:{value:(_vm.affiliateId),callback:function ($$v) {_vm.affiliateId=$$v},expression:"affiliateId"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Tune Domain","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors[0],"items":_vm.domainsArr,"clearable":"","item-value":"id","item-text":"tuneDomain","label":"Tune Domain","outlined":""},model:{value:(_vm.tuneDomainId),callback:function ($$v) {_vm.tuneDomainId=$$v},expression:"tuneDomainId"}})]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"button-bar between"},[_c('v-btn',{attrs:{"color":"teal","outlined":""},on:{"click":function($event){_vm.e1 = 1}}},[_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-step-backward ")]),_c('div',{staticClass:"back-buton"},[_vm._v(" Go back ")])],1),_c('v-btn',{attrs:{"disabled":invalid || _vm.isLoading,"loading":_vm.isLoading,"color":"primary"},on:{"click":_vm.stepTwo},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,true)},[_vm._v(" Continue "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-step-forward ")])],1)],1)]}}])})],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('ValidationObserver',{ref:"sThreeObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"mb-12 step3-card"},[_c('v-row',[_c('v-col',[(_vm.areThereFixVals)?_c('h1',{staticClass:"fixed-values"},[_vm._v(" Fixed Values ")]):_vm._e(),_c('div',{ref:"newUtmFields",staticClass:"utm-fields"})])],1),_vm._l((_vm.affUI.length / 2),function(n,idx){return _c('v-row',{key:idx,attrs:{"align":"end","justify":"space-around"}},[_c('v-col',{ref:"col1",refInFor:true,attrs:{"cols":"6"}},[_c('utm-field',{attrs:{"disabled":_vm.affUI[idx].disabled,"dynamically-created":_vm.affUI[idx].value !== undefined,"items":!_vm.affUI[idx].value
                                            ? _vm.getVariablesArr(
                                                _vm.affUI[idx].label
                                            )
                                            : [],"label":_vm.affUI[idx].label,"name":_vm.affUI[idx].name,"rules":_vm.affUI[idx].rules,"value":_vm.affUI[idx].value,"item-value":"variableValue","item-text":"variableValue"},model:{value:(
                                        _vm.affParams[
                                            ("affSub" + (idx !== 0 ? idx + 1 : ''))
                                        ]
                                    ),callback:function ($$v) {_vm.$set(_vm.affParams, 
                                            ("affSub" + (idx !== 0 ? idx + 1 : ''))
                                        , $$v)},expression:"\n                                        affParams[\n                                            `affSub${\n                                                idx !== 0 ? idx + 1 : ''\n                                            }`\n                                        ]\n                                    "}})],1),_c('v-col',{ref:"col2",refInFor:true,attrs:{"cols":"6"}},[_c('utm-field',{attrs:{"disabled":_vm.affUI[idx + 5].disabled,"dynamically-created":_vm.affUI[idx + 5].value !== undefined,"items":!_vm.affUI[idx + 5].value
                                            ? _vm.getVariablesArr(
                                                _vm.affUI[idx + 5].label
                                            )
                                            : [],"label":_vm.affUI[idx + 5].label,"name":_vm.affUI[idx + 5].name,"rules":_vm.affUI[idx + 5].rules,"value":_vm.affUI[idx + 5].value,"item-value":"variableValue","item-text":"variableValue"},model:{value:(
                                        _vm.affParams[("affUnique" + (idx + 1))]
                                    ),callback:function ($$v) {_vm.$set(_vm.affParams, ("affUnique" + (idx + 1)), $$v)},expression:"\n                                        affParams[`affUnique${idx + 1}`]\n                                    "}})],1)],1)})],2),_c('div',{staticClass:"button-bar between"},[_c('v-btn',{attrs:{"color":"teal","outlined":""},on:{"click":function($event){_vm.e1 = 2}}},[_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-step-backward ")]),_c('div',{staticClass:"back-buton"},[_vm._v(" Go back ")])],1),_c('v-btn',{attrs:{"disabled":invalid || _vm.isLoading,"loading":_vm.isLoading,"color":"primary"},on:{"click":_vm.stepThree},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,true)},[_vm._v(" Save "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-content-save-outline ")])],1)],1)]}}])})],1),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('div',{staticClass:"finalStep"},[_c('div',[_vm._v(" Campaign Completed ")]),_c('p',[_vm._v(" The Generate URL is: ")]),_c('div',[_c('clipboard-icon',{attrs:{"value":_vm.finalUrl
                                    ? decodeURIComponent(_vm.finalUrl.href)
                                    : ''}})],1)]),_c('v-card',{staticClass:"d-flex justify-space-between mb-6",attrs:{"flat":"","tile":""}},[_c('v-btn',{attrs:{"color":"teal","outlined":""},on:{"click":function($event){_vm.e1 = 3}}},[_vm._v(" Edit Campaign ")]),_c('v-btn',{attrs:{"color":"green"},on:{"click":_vm.goToCampaignList}},[_vm._v(" Complete ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }