<template>
    <v-col class="utm-field-container">
        <v-checkbox
            v-model="setCustomValue"
            v-if="!dynamicallyCreated && items.length"
            :label="`Custom Value`"
            @change="clearFieldValue"
            class="utm-field-chkBox"
        />
        <ValidationProvider
            :name="name"
            :rules="rules"
            class="utm-field-row"
            v-slot="{ errors }"
        >
            <div class="utm-field-prepend">
                {{ name }}:
            </div>
            <v-text-field
                v-if="showTextField"
                v-model="fieldValue"
                :error-messages="errors[0]"
                :disabled="disabled"
                :label="label"
                @keyup="onChange"
                class="utm-field-txt"
                outlined
            />

            <v-autocomplete
                v-else
                v-model="fieldValue"
                :error-messages="errors[0]"
                :items="items"
                :label="label"
                :item-value="itemValue"
                :item-text="itemText"
                @change="onChange"
                outlined
            />
        </ValidationProvider>
    </v-col>
</template>

<script>
    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },

            dynamicallyCreated: {
                type: Boolean,
                default: false,
            },

            items: {
                type: Array,
                default: () => [],
            },

            itemText: {
                type: String,
                default: undefined,
            },

            itemValue: {
                type: String,
                default: undefined,
            },

            label: {
                type: String,
                default: undefined,
            },

            name: {
                type: String,
                default: undefined,
            },

            parent: {
                type: Object,
                default: () => {},
            },

            rules: {
                type: String,
                default: undefined,
            },

            value: {
                type: String | Number,
                default: undefined,
            },
        },

        data: () => ({
            fieldValue: undefined,
            setCustomValue: false,
        }),

        computed: {
            showTextField () {
                return this.setCustomValue || !this.items.length
            },
        },

        methods: {
            onChange (e) {
                const val = e.target ? e.target.value : e
                this.$emit('input', val)
            },

            clearFieldValue () {
                this.fieldValue = undefined
            },
        },

        mounted () {
            this.fieldValue = this.value
        },
    }
</script>

<style lang="scss">
.utm-field-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  & .utm-field-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    & .utm-field-prepend {
        margin-right: 20px;
        margin-top: 15px;
        min-width: 150px;
        text-align: right;
    }
  }
}

.utm-field-chkBox {
  margin-right: 10px;
  margin-top: 0;
}

.utm-field-txt {
  width: 235px;
}

.v-input__slot {
  margin-bottom: 0 !important;
}
</style>