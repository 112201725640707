<template>
    <crud-table
        :table="tableName"
        :headers="headers"
        :items="items"
        :modal-max-width="'350px'"
        :obj-template="defaultItem"
        item-name="Pub Code"
        @onDelete="deletePC"
        @onSave="savePC"
    >
        <template #newItemForm>
            <v-col
                cols="12"
            >
                <ValidationProvider
                    name="Pub Code"
                    rules="required|max:3|alpha_dash"
                    v-slot="{ errors }"
                >
                    <v-text-field
                        v-model="pubCode"
                        label="Pub Code"
                        outlined
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>

                <ValidationProvider
                    name="Product Slug"
                    rules="alpha_dash"
                    v-slot="{ errors }"
                >
                    <v-text-field
                        v-model="productSlug"
                        label="Product Slug"
                        outlined
                        required
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
            </v-col>
        </template>
    </crud-table>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import CrudTable from './CrudTable.vue'

    export default {
        components: { CrudTable },

        data: () => ({

            defaultItem: {
                id: -1,
                pubCode: '',
                productSlug: '',
            },

            headers: [
                { text: 'ID', align: 'start', sortable: false, value: 'id' },
                { text: 'Pub Code', value: 'pubCode', sortable: true },
                { text: 'Product Slug', value: 'productSlug', sortable: true },
            ],

            actions: { text: 'Actions', value: 'actions', sortable: false },

            tableName: 'Pub Codes',
        }),

        computed: {
            ...mapGetters( ['getPubCodes', 'getStateAttr'] ),

            items () {
                return this.getPubCodes
            },

            editedItem () {
                return this.getStateAttr('editedItem')
            },

            pubCode: {
                get() {
                    return this.editedItem.pubCode
                },

                set(value) {
                    this.updateEditedItem({ attr: 'pubCode', value })
                },
            },

            productSlug: {
                get() {
                    return this.editedItem.productSlug
                },

                set(value) {
                    this.updateEditedItem({ attr: 'productSlug', value })
                },
            },
        },

        methods: {
            ...mapActions([
                'fetchTuneOffers',
                'fetchPubCodes', 'createPubCode', 'updatePubCode',
                'deletePubCode', 'updateEditedItem', 'setTablePermissions',
            ]),

            initialize () {
                this.fetchPubCodes()
                this.fetchTuneOffers()

                if(this.$isAdmin()) {
                    this.headers.push(this.actions)
                    this.setTablePermissions()
                } else {
                    this.setTablePermissions({ add: true, edit: false })
                }
            },

            savePC () {

                const { id } = this.editedItem

                if ( id > -1 ) {
                    this.updatePubCode()
                } else {
                    this.createPubCode()
                }
            },

            deletePC () {
                this.deletePubCode()
            },
        },

        created () {
            this.initialize()
        },
    }
</script>