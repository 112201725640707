<template>
    <Loader message="Wait, validating the auth info ..." />
</template>

<script>
    import Loader from '../components/Loader.vue'

    export default {
        components: { Loader },

        mounted() {
            this.$auth
                .login({
                    url: process.env.VUE_APP_API_ENDPOINT + process.env.VUE_APP_SSO_VALIDATION_LAMBDA,
                    method: 'POST',
                    data: {
                        token: this.$route.query.t,
                        signature: this.$route.query.s,
                    },
                    staySignedIn: true,
                    fetchUser: false,
                    redirect: { name: 'home' },
                })
                .then( async (res) => {
                    this.$store.dispatch('fetchUser', res.data.data)
                })
        },
    }
</script>
