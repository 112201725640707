<template>
    <v-navigation-drawer
        app
        :mini-variant.sync="mini"
        v-model="drawer"
    >
        <v-list-item class="px-2">
            <v-list-item-avatar tile>
                <v-img src="../assets/logo.png" />
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title class="text-h6">
                    URL Builder
                </v-list-item-title>
            </v-list-item-content>

            <v-btn
                icon
                @click.stop="mini = !mini"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
        </v-list-item>

        <v-divider />

        <v-list
            dense
            nav
        >
            <div v-for="item in items" :key="item.title">
                <v-list-item
                    v-if="!item.subLinks"
                    :to="item.href"
                    link
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group
                    v-else
                    :key="item.title"
                    no-action
                >
                    <template #activator>
                        <v-list-item :class="[ mini ? 'group-icon-mini' : '']">
                            <v-list-item-icon :class="[ mini ? '' : 'group-icon-expanded' ]">
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-list-item
                        v-for="sublink in item.subLinks"
                        :to="sublink.href"
                        :key="sublink.title"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ sublink.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ sublink.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </div>
            <v-list-item class="logout-item" @click="logout">
                <v-list-item-icon>
                    <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    export default {

        data () {
            return {
                drawer: true,
                mini: false,
                items: [
                    { title: 'Create Campaign', icon: 'mdi-lead-pencil', href: '/create-campaign' },
                    { title: 'Campaigns', icon: 'mdi-view-dashboard', href: '/campaign-urls' },
                    { title: 'Tune Offer URLs', icon: 'mdi-link-variant', href: '/tune-offer-url' },
                    {
                        title: 'Admin',
                        icon: 'mdi-account-cog-outline',
                        href: '#',
                        subLinks: [
                            { title: 'Variables', icon: 'mdi-puzzle', href: '/url-variables' },
                            { title: 'Pub Codes', icon: 'mdi-code-braces', href: '/pub-codes' },
                            { title: 'Content Codes', icon: 'mdi-barcode-scan', href: '/content-codes' },
                            { title: 'Publisher Campaigns', icon: 'mdi-lightbulb', href: '/publisher-campaigns' },
                            { title: 'Variable Types', icon: 'mdi-fingerprint', href: '/url-variable-types' },
                            { title: 'Sources', icon: 'mdi-qrcode', href: '/sources' },
                            { title: 'URL Templates', icon: 'mdi-domain', href: '/url-templates' },
                            { title: 'Users', icon: 'mdi-account-group', href: '/users' },
                        ],
                    },
                ],

                right: null,
            }
        },

        methods: {
            logout() {
                this.$auth
                    .logout({
                        makeRequest: false,
                        redirect: { name: 'login' },
                    })
            },
        },
    }
</script>

<style lang="scss">
  .group-icon-expanded {
      margin-left: -7px;
  }
  .group-icon-mini {
      padding: 0 !important;
  }
  .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 15px;
  }
</style>