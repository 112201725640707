<template>
    <crud-table
        :table="tableName"
        :headers="headers"
        :items="items"
        :obj-template="defaultItem"
        view-details
        item-name="Url"
        :view-delete-actn="false"
        @onDelete="deleteTOU"
        @onEditingItem="setPubCode"
        @onModalClose="resetVars"
        @onSave="saveTOU"
    >
        <template #newItemForm>
            <v-col cols="12">
                <ValidationProvider
                    name="Source"
                    rules="required"
                    v-slot="{ errors }"
                >
                    <v-autocomplete
                        :error-messages="errors[0]"
                        :items="sources"
                        auto-select-first
                        clearable
                        item-value="id"
                        label="Source"
                        outlined
                        v-model="sourceId"
                    >
                        <template #item="{ item }">
                            {{ `${item.sourceName} - ${item.sourceShortCode}` }}
                        </template>
                        <template #selection="{ item }">
                            {{ `${item.sourceName} - ${item.sourceShortCode}` }}
                        </template>
                    </v-autocomplete>
                </ValidationProvider>
            </v-col>
            <v-col cols="12">
                <v-autocomplete
                    :items="pubCodes"
                    auto-select-first
                    clearable
                    item-value="id"
                    item-text="pubCode"
                    label="Pub Code"
                    outlined
                    v-model="pubCodeId"
                >
                    <template #item="{ item }">
                        {{ `${item.pubCode} - ${item.productSlug}` }}
                    </template>
                    <template #selection="{ item }">
                        {{ `${item.pubCode} - ${item.productSlug}` }}
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12">
                <ValidationProvider
                    name="Content Code"
                    rules="required"
                    v-slot="{ errors }"
                >
                    <v-autocomplete
                        :error-messages="errors[0]"
                        :items="contentCodesArr"
                        auto-select-first
                        clearable
                        item-value="id"
                        item-text="contentCode"
                        label="Content Code"
                        outlined
                        v-model="contentCodeId"
                    >
                        <template #item="{ item }">
                            {{ `${item.contentCode} - ${item.descriptiveName}` }}
                        </template>
                        <template #selection="{ item }">
                            {{ `${item.contentCode} - ${item.descriptiveName}` }}
                        </template>
                    </v-autocomplete>
                    <v-alert
                        icon="mdi-link-variant"
                        outlined
                        text
                        type="info"
                    >
                        {{ ccBaseUrl }}
                    </v-alert>
                </ValidationProvider>
            </v-col>
        </template>

        <template #details>
            <v-simple-table>
                <template #default>
                    <tbody id="detailsBody">
                        <tr>
                            <td>ID</td>
                            <td>{{ editedItem.id }}</td>
                        </tr>
                        <tr>
                            <td>User</td>
                            <td>
                                {{
                                    editedItem.user
                                        ? `${editedItem.user.firstName} ${editedItem.user.lastName}`
                                        : ''
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>Offer Template</td>
                            <td>
                                {{
                                    editedItem.source
                                        ? `${editedItem.source.tuneTemplateUrl}`
                                        : ''
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>Tune Offer ID</td>
                            <td>
                                {{
                                    editedItem.contentCode
                                        ? `${editedItem.contentCode.tuneOfferId}`
                                        : ''
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>Tune Offer URL ID</td>
                            <td>{{ `${editedItem.tuneOfferUrlId}` }}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{{ `${editedItem.urlName}` }}</td>
                        </tr>
                        <tr>
                            <td>Source</td>
                            <td>
                                {{
                                    editedItem.source
                                        ? `${editedItem.source.sourceShortCode} - ${editedItem.source.sourceName}`
                                        : ''
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>Content Code</td>
                            <td>
                                {{
                                    editedItem.contentCode
                                        ? `${editedItem.contentCode.contentCode}`
                                        : 'editedItem.contentCode'
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>Generated URL</td>
                            <td>
                                {{
                                    `${decodeURIComponent(
                                        editedItem.generatedUrl
                                    )}`
                                }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
    </crud-table>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import CrudTable from './CrudTable.vue'

    export default {
        name: 'TuneOfferUrls',
        components: { CrudTable },

        data: () => ({
            pubCodeId: undefined,
            ccBaseUrl: undefined,

            defaultItem: {
                id: -1,
                userId: -1,
                contentCodeId: -1,
                sourceId: -1,
            },

            headers: [
                { text: 'ID', align: 'start', sortable: false, value: 'id' },
                { text: 'Tune Offer ID & Offer URL Name', value: 'urlName' },
                {
                    text: 'Tune Offer URL ID',
                    value: 'tuneOfferUrlId',
                    align: 'center',
                },
                {
                    text: 'Offer Template',
                    value: 'source',
                    align: 'center',
                },
                { text: 'Base URL', value: 'contentCode' },
                { text: 'Pub Code', value: 'contentCode.pubCode.pubCode', align: 'center' },
                { text: 'Created By', value: 'user', align: 'center' },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    align: 'center',
                    width: '100px',
                },
            ],

            tableName: 'Tune Offer URLs',
        }),

        computed: {
            ...mapGetters({
                contentCodes: ['getContentCodes'],
                getStateAttr: ['getStateAttr'],
                pubCodes: ['getPubCodes'],
                sources: ['getSources'],
                tuneOfferUrls: ['getTuneOfferUrls'],
                user: ['getUser'],
            }),

            editedItem() {
                return this.getStateAttr('editedItem')
            },

            items() {
                return this.tuneOfferUrls
            },

            contentCodesArr() {
                return this.pubCodeId
                    ? this.contentCodes.filter(
                        (cc) => cc.pubCode.id === this.pubCodeId
                    )
                    : this.contentCodes
            },

            sourceId: {
                get() {
                    return this.editedItem.source
                        ? this.editedItem.source.id
                        : this.editedItem.sourceId
                },

                set(value) {
                    this.updateEditedItem({ attr: 'sourceId', value })
                },
            },

            contentCodeId: {
                get() {
                    return this.editedItem.contentCode
                        ? this.editedItem.contentCode.id
                        : this.editedItem.contentCodeId
                },

                set(value) {
                    this.updateEditedItem({ attr: 'contentCodeId', value })
                    this.ccBaseUrl = value
                        ? this.contentCodesArr.find(cc => cc.id === value).baseUrl
                        : ''
                },
            },
        },

        methods: {
            ...mapActions([
                'fetchSources',
                'fetchTuneOfferUrls',
                'createTuneOfferUrls',
                'updateTuneOfferUrls',
                'deleteTuneOfferUrls',
                'updateEditedItem',
                'setTablePermissions',
            ]),

            initialize() {
                this.setTablePermissions()
                this.fetchSources()
                this.fetchTuneOfferUrls()
            },

            resetVars() {
                this.pubCodeId = undefined
                this.ccBaseUrl = undefined
            },

            setPubCode() {
                this.pubCodeId = this.editedItem.contentCode?.pubCode.id
                this.ccBaseUrl = this.editedItem.contentCode?.baseUrl
            },

            saveTOU() {
                this.updateEditedItem({ attr: 'userId', value: this.user.userId  })

                const { id } = this.editedItem

                if (id > -1) {
                    this.updateTuneOfferUrls()
                } else {
                    this.createTuneOfferUrls()
                }
            },

            deleteTOU() {
                this.deleteTuneOfferUrls()
            },
        },

        created() {
            this.initialize()
        },
    }
</script>

<style lang="scss">
#detailsBody > tr > td {
    width: 150px !important;
}
#detailsBody > tr > td:last-child {
    max-width: 340px !important;
    width: 400px !important;
}
.filters {
    display: flex;
    align-items: center;
}
</style>
