<template>
    <div>
        <crud-table
            :table="tableName"
            :headers="headers"
            :items="urlVariableItems"
            :obj-template="defaultItem"
            @onDelete="deleteUV"
            @onSave="saveUV"
            item-name="Variable"
            sort-by="id"
            class="elevation-1"
        >
            <template #newItemForm>
                <v-col cols="12" sm="8" md="6">
                    <ValidationProvider
                        name="Variable Name"
                        rules="required|max:100"
                        v-slot="{ errors }"
                    >
                        <v-text-field
                            v-model="variableValue"
                            label="Variable Name"
                            outlined
                            :error-messages="errors[0]"
                        />
                    </ValidationProvider>

                    <ValidationProvider
                        name="Variable Short Code"
                        rules="required|max:100|alpha_dash"
                        v-slot="{ errors }"
                    >
                        <v-text-field
                            v-model="shortValue"
                            label="Variable Short Code"
                            outlined
                            :error-messages="errors[0]"
                        />
                    </ValidationProvider>

                    <ValidationProvider
                        name="Variable Type ID"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <v-select
                            :items="urlVariableTypes"
                            v-model="variableTypeId"
                            item-value="id"
                            item-text="variableType"
                            label="Variable Type ID"
                            outlined
                            :error-messages="errors[0]"
                        />
                    </ValidationProvider>
                </v-col>
            </template>
        </crud-table>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import CrudTable from './CrudTable.vue'

    export default {
        name: 'UrlVariables',
        components: { CrudTable },

        data: () => ({
            defaultItem: {
                id: -1,
                variableValue: '',
                variableTypeId: 0,
                shortValue: '',
                sourceFilter: null,
            },

            headers: [
                { text: 'ID', align: 'start', sortable: false, value: 'id' },
                { text: 'Name', value: 'variableValue', sortable: true },
                { text: 'Short Code', value: 'shortValue', sortable: true },
                { text: 'Type ID', value: 'variableTypeValue', sortable: true },
            ],

            actions: {
                text: 'Actions',
                value: 'actions',
                sortable: false,
                align: 'end',
            },

            tableName: 'URL Variables',
        }),

        computed: {
            ...mapGetters({
                urlVariables: ['getUrlVariables'],
                urlVariableTypes: ['getUrlVariableTypes'],
                getStateAttr: ['getStateAttr'],
            }),

            // what
            urlVariableItems() {
                return this.urlVariables.map((u) => {
                    return {
                        id: u.id,
                        variableValue: u.variableValue,
                        shortValue: u.shortValue,
                        variableTypeId: u.variableType.id,
                        variableTypeValue: this.urlVariableTypeValue(
                            u.variableType.id
                        )?.variableType,
                        filterId: u.filterId,
                    }
                })
            },

            editedItem() {
                return this.getStateAttr('editedItem')
            },

            variableValue: {
                get() {
                    return this.editedItem.variableValue
                },

                set(value) {
                    this.updateEditedItem({ attr: 'variableValue', value })
                },
            },

            shortValue: {
                get() {
                    return this.editedItem.shortValue
                },

                set(value) {
                    this.updateEditedItem({ attr: 'shortValue', value })
                },
            },

            variableTypeId: {
                get() {
                    return this.editedItem.variableTypeId
                },

                set(value) {
                    this.updateEditedItem({ attr: 'variableTypeId', value })
                },
            },
        },

        methods: {
            ...mapActions([
                'fetchUrlVariableTypes',
                'fetchUrlVariables',
                'createUrlVariable',
                'updateUrlVariable',
                'updateEditedItem',
                'deleteUrlVariable',
                'setTablePermissions',
            ]),

            initialize() {
                this.fetchUrlVariableTypes()
                this.fetchUrlVariables()

                if (this.$isAdmin()) {
                    this.headers.push(this.actions)
                    this.setTablePermissions()
                } else {
                    this.setTablePermissions({ add: true, edit: false })
                }
            },

            urlVariableTypeValue(urlVariableTypeID) {
                if (this.urlVariableTypes.length) {
                    return this.urlVariableTypes.find(
                        (u) => u.id === urlVariableTypeID
                    )
                }
            },

            saveUV() {
                const { id } = this.editedItem

                if (id > -1) {
                    this.updateUrlVariable()
                } else {
                    this.createUrlVariable()
                }
            },

            deleteUV() {
                this.deleteUrlVariable()
            },
        },

        created() {
            this.initialize()
        },
    }
</script>
