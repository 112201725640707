import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

Vue.use(Vuex)

const state = {
    affiliates: [],
    campaignUrls: [],
    contentCodes: [],
    domains: [],
    editedItem: {},
    isAuthenticated: false,
    keepEditedItem: false,
    loading: false,
    pubCodes: [],
    publisherCampaigns: [],
    sources: [],
    tablePermissions: {},
    token: localStorage.getItem('token'),
    tuneOffers: [],
    tuneOfferUrls: [],
    urlTemplates: [],
    urlVariableTypes: [],
    urlVariables: [],
    user: undefined,
    users: [],
}

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
})