var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"table-name"},[_vm._v(" "+_vm._s(_vm.table)+" ")]),(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"search":_vm.search,"sort-by":"id"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" There is no data ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Loading... Please wait ")]},proxy:true},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.user.firstName) + " " + (item.user.lastName)))+" ")]}},{key:"item.generatedUrl",fn:function(ref){
var item = ref.item;
return [_c('clipboard-icon',{attrs:{"value":item.generatedUrl}})]}},{key:"item.urlName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-btn',{staticStyle:{"margin-right":"15px"},attrs:{"color":"indigo","dark":"","elevation":"1","x-small":""}},[_vm._v(" "+_vm._s(item.contentCode ? item.contentCode.tuneOfferId : '')+" ")]),_c('p',{staticStyle:{"margin-bottom":"0 !important"}},[_vm._v(" "+_vm._s(item.urlName)+" ")])],1)]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(item.source.tuneTemplateUrl)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.source.tuneTemplateUrl))])])]}},{key:"item.contentCode",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(item.contentCode ? item.contentCode.baseUrl : '')+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.contentCode ? item.contentCode.baseUrl : ''))])])]}},{key:"item.baseUrl",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(item.baseUrl)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.baseUrl))])])]}},{key:"item.tuneTemplateUrl",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(item.tuneTemplateUrl)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tuneTemplateUrl))])])]}},{key:"item.asanaProjectLink",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"color":"pink","href":item.asanaProjectLink,"icon":"","rel":"noreferrer noopener","target":"_blank"}},[_c('v-icon',[_vm._v("mdi-link")])],1)]}}],null,true)},[_c('span',[_vm._v("Asana Project Link")])])]}},{key:"item.dateAdded",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dateAdded))+" ")]}},{key:"item.dateModified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dateModified))+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"search-bar",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":""},model:{value:(_vm.inputSearch),callback:function ($$v) {_vm.inputSearch=$$v},expression:"inputSearch"}}),_c('v-spacer'),(_vm.permissions.add || _vm.permissions.edit)?_c('v-dialog',{attrs:{"max-width":_vm.modalMaxWidth},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.permissions.add)?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New "+_vm._s(_vm.itemName)+" ")]):_vm._e()]}}],null,false,3807777881),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',[_c('v-container',[_c('v-row',[_vm._t("newItemForm")],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)]}}],null,true)})],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Details ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_vm._t("details")],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDetails}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},(_vm.permissions.edit)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.viewDetails)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDetails(item)}}},[_vm._v(" mdi-eye ")]):_vm._e(),(_vm.editAndRedirect)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editAndRedirectFn(item)}}},[_vm._v(" mdi-pencil ")]):(!item.isLocked && !item.isLocked === true)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(
                    _vm.viewDeleteActn &&
                        !item.isLocked &&
                        !item.isLocked === true
                )?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}:null],null,true)}):_c('v-skeleton-loader',_vm._b({attrs:{"type":"table-heading, table-thead, table-tbody"}},'v-skeleton-loader',_vm.attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }